/* eslint-disable react/no-danger */
import React from 'react'


function JobBoardBlock() {
	return (
		<div id="careers" className="wrapper">
			<script src="https://boards.eu.greenhouse.io/embed/job_board/js?for=ultraleapltd"></script>

			<div id="grnhse_app"
				className="max-w-4xl mx-auto">
				Fetching Jobs<br />
			</div>
		</div>
	)
}

export default JobBoardBlock
