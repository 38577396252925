/* eslint-disable react/prop-types */
import React from 'react'
import classNames from 'classnames'
import Switch from '@/utils/Switch'
import CoreButton from '@/components/core/Button'
import { Paragraph } from '@/components/core/Paragraph'
import { Heading } from '@/components/core/Heading'
import { CoreImage } from '@/components/core/Image'
import { List } from '@/components/core/List'

function Columns({ blocks }) {
	return (
		<div className="wrapper">
			<div className="flex-wrap items-center lg:flex lg:-ml-8">
				{blocks.map(block => (
					<div className="lg:w-1/2 lg:pl-8" key={block.id}>
						{block.blocks.map(item => (
							<div
								className={classNames({
									'max-w-md mx-auto': item.name !== 'core/image'
								})}
								key={item.id}
							>
								<Switch test={item.name}>
									<Heading case="core/heading" {...item} />
									<Paragraph case="core/paragraph" {...item} />
									<CoreImage case="core/image" {...item} />
									<CoreButton case="core/button" {...item} />
									<List case="core/list" {...item} />
								</Switch>
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	)
}

export default Columns
