/* eslint-disable react/prop-types */
import React from 'react'
import classNames from 'classnames'
import Image from '@/utils/Image'
import styles from './UseCaseHero.module.css'

function UseCaseHero({
	lead_in,
	lead_In,
	heading,
	background_image,
	article_hero_background_image,
	hero_colour = 'rgb(117, 53, 222)',
	icon,
	compType = "UseCaseHero"
}) {
	background_image = background_image || article_hero_background_image
	return (
		<div className={classNames('normal pb-20')}>
			<div className="site-grid">
				<div
					className={classNames(
						styles.bgBlock,
						'relative top-20 hidden lg:block lg:px-8'
					)}
				>
					<div
						className="w-full h-full"
						style={{ backgroundColor: hero_colour }}
					/>
				</div>
				<div className={classNames(styles.imageBox, 'lg:px-8')}>
					<div className="relative h-full">

						<Image 
							className={classNames(compType,'h-full md:h-auto object-cover')}
							style={{ width: '100%', height: '100%' }}
							{...background_image}
						/>
						<div className="absolute inset-0 bg-black-30" />
					</div>
				</div>
				<div
					className={classNames(
						styles.content,
						'relative z-10 flex items-center py-4'
					)}
				>
					<div className="w-full max-w-3xl">
						<p className="w-5/6 mb-6 text-2xl leading-snug text-white font-museoSans">
							{lead_in || lead_In }
						</p>
						<h1 className="mb-6 leading-tight text-white whitespace-pre-line text-h1-fluid font-museoSansBold tracking-wide">
							{heading}
						</h1>
						<div className="w-20">
							<Image {...icon} style={{minWidth: '0px'}}/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default UseCaseHero
