import React from 'react'
import classNames from 'classnames'
import { v4 as uuidv4 } from 'uuid';
import { motion } from 'framer-motion'
import Button from '@/components/global/Button'
import Link from '@/utils/Link'
import Node from '@/utils/Node'
import AnimateEntrance from '@/utils/AnimateEntrance'
import { containerVariants, textVariants } from '@/utils/transitions'
import styles from './PullOutBlock.module.css'

function PullOutBlock({
    pull_out_title,
    pull_out_title_colour,
    pull_out_title_alignment, 
    pull_out_copy,
    pull_out_copy_colour,
    pull_out_background_colour,
    pull_out_cta_text,
    pull_out_cta_link,
    pull_out_cta_colour,
    size
}) {
    // TODO: waiting on CMS
    const cta_colour = 'transparent'
	return (

        <AnimateEntrance
            variants={containerVariants}
            threshold={0.1}
            rootMargin="-100px 0px"
            style={{
                backgroundColor: pull_out_background_colour
            }}
        >
            <div className={classNames(
                "flex flex-col w-full mx-auto md:wrapper",
                size === 'large' ? 'py-32' : 'py-16'
            )}>
                <motion.div 
                    variants={textVariants}
                    key={uuidv4()} 
                    className="mb-16 lg:mb-0"
                >
                    {pull_out_title && (
                        <h2 
                            style={{color: pull_out_title_colour, textAlign: pull_out_title_alignment}}
                            className="leading-tight text-h2-fluid font-museoSansBold md:mb-8"
                        >
                            {pull_out_title}
                        </h2>
                    )}
                    {pull_out_copy && (
                        <Node
                            as="div"
                            className={classNames(styles.text, 'mb-8')}
                            style={{color: pull_out_copy_colour}}
                            dangerouslySetInnerHTML={{ __html: pull_out_copy }}
                        />
                    )}
                    {pull_out_cta_text && (
                        <div 
                            style={{justifyContent: pull_out_title_alignment}}
                            className="w-full mt-8 -mr-4 flex"
                        >
                            <Button
                                as={Link}
                                to={`${pull_out_cta_link}`}
                                theme={cta_colour}
                                className={classNames(
                                    "mb-4 mr-4 md:mb-0",
                                    size === 'large' && 'px-8 hover:px-12 text-lg' 
                                )}
                            >
                                {pull_out_cta_text}
                            </Button>									
						</div>
                    )}
                </motion.div>
            </div>
        </AnimateEntrance>
	)
}

export default PullOutBlock
