import React, { useEffect, useState } from 'react'
import { extract } from 'oembed-parser-anywhere'
import classNames from 'classnames'
import domify from 'domify'

const fetchEmbed = async url => extract(url)

function Embed({ content, provider }) {
	const [embed, setEmbed] = useState()
	const [caption, setCaption] = useState()
	useEffect(() => {
		if (window && !embed && content) {
			const nodes = domify(content)
			const embedNode = nodes.querySelector('.wp-block-embed__wrapper')
            let embedUrl = embedNode.textContent.trim()
			const captionNode = nodes.querySelector('figcaption')

			if(captionNode) {
				setCaption(captionNode.textContent.trim())
			}

            if(embedUrl && provider === 'youtube') {
                if(embedUrl.includes('watch?v=')) embedUrl = embedUrl.replace('watch?v=', 'embed/')
                if(embedUrl.includes('youtu.be')) embedUrl = embedUrl.replace('youtu.be', 'www.youtube.com/embed')
            }
            if(embedUrl && provider === 'vimeo') {
                embedUrl = embedUrl.replace('vimeo.com', 'player.vimeo.com/video')
            }

			setEmbed(embedUrl)
		}
	}, [content, embed, provider])

	const isVideo = provider === 'vimeo' || provider === 'youtube'

	return (
		<figure className="mb-12">
			{embed && (
				<div
					className={classNames({
						'aspect-ratio-16/9 relative video-wrapper': isVideo,
						'flex justify-center': !isVideo
					})}
				>
                    <iframe 
                        title={`embed_${provider}`}
                        src={embed}
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </div>

			)}
			{caption && (
				<figcaption className="text-sm text-darkgrey px-8 md:px-0" dangerouslySetInnerHTML={{ __html: caption }} />

			)}
		</figure>
	)
}

export default Embed
