/* eslint-disable react/no-danger */
import React from 'react'
import styles from './List.module.css'
import classNames from 'classnames'

function List({ content }) {
	return (
		<div
			className={classNames('text-lg leading-relaxed text-darkgrey break-words uh-list-style pb-6 pl-2 pr-2', styles.list)}
			dangerouslySetInnerHTML={{ __html: content }}
		/>
	)
}

export default List
