import React, { Children, createElement } from 'react'
import { node, oneOfType, string, number } from 'prop-types'

export default function Switch({ children, test }) {
	return Children.map(children, child => {
		const { case: type, ...rest } = child.props
		const render = test === type ? createElement(child.type, rest) : null
		return render
	})
}

Switch.propTypes = {
	children: node.isRequired,
	test: oneOfType([string, number]).isRequired // eslint-disable-line react/forbid-prop-types
}

export function Case({ children, className, index }) {
	return (
		<div id={`_row_${index}_`} className={className}>
			{children}
		</div>
	)
}
