import React from 'react'
import { string, number, object } from 'prop-types'
import Form from '@/components/Form'
import useForm from '@/hooks/useForm'

function TextFormBlock({
	text_form_block_title,
	text_form_block_text,
	text_form_block_image,
	text_form_block_form_id
}) {
	const { nodes } = useForm()
	const form = nodes.find(item => item.wordpress_id === text_form_block_form_id)

	let source_url = ''
	let alt_text = ''

	if (text_form_block_image) {
		source_url = text_form_block_image.source_url
		alt_text = text_form_block_image.alt_text
	}

	return (
		<section className="flex flex-wrap md:flex-no-wrap">
			<div className="w-full md:w-3/5 mr-0 md:mr-8 mb-8 md:mb-0">
				{source_url ? (
					<img
						src={source_url}
						alt={alt_text}
						className="float-right pt-2 pl-4 pb-4 max-w-1/2"
					/>
				) : null}
				<p className="whitespace-pre-line">{text_form_block_text}</p>
			</div>
			<div className="w-full md:w-2/5">
				<h3 className="mb-6 leading-tight text-black text-h3-fluid font-museoSans">
					{text_form_block_title}
				</h3>
				<div className="mx-auto">
					<Form {...form.acf} />
				</div>
			</div>
		</section>
	)
}

TextFormBlock.propTypes = {
	text_form_block_title: string,
	text_form_block_text: string,
	text_form_block_form_id: number,
	text_form_block_form_text: string,
	text_form_block_image: object
}

export default TextFormBlock
