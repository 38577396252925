/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import AnimateEntrance from '@/utils/AnimateEntrance'
import {
    textVariants,
    bannerVariants,
	containerVariants
} from '@/utils/transitions'
import styles from '../HeroShouter/HeroShouter.module.css'

function TextShouter({
	text_shouter_key_copy,
	text_shouter_key_word,
	className = 'text-secondary'
}) {
	return (
		<AnimateEntrance
			threshold={0}
			rootMargin="0% 0px"
			variants={containerVariants}
			className={classNames(
				'relative flex items-center w-full',
				className
			)}
		>
            <motion.div
				variants={bannerVariants}
				className="absolute inset-y-0 flex items-center mx-auto md:inset-0 -left-1/2 -right-1/2 md:w-full"
			>
                <div
                    className={classNames(
                        'relative text-center font-bold mx-auto opacity-5',
                        styles.heading
                    )}
                    dangerouslySetInnerHTML={{ __html: text_shouter_key_word }}
                />
			</motion.div>
			<motion.div
				variants={textVariants}
				className="relative left-0 right-0 max-w-4xl px-8 mx-auto my-0 text-2xl text-center lg:text-3xl top-2 font-museoSans"
				dangerouslySetInnerHTML={{
					__html: text_shouter_key_copy.replace(/(?:\r\n|\r|\n)/g, '<br>')
				}}
			/>
		</AnimateEntrance>
	)
}

export default TextShouter
