/* eslint-disable react/no-danger */
import React from 'react'
import ReactHtmlParser from 'react-html-parser'; 
import classNames from 'classnames'
import { v4 as uuidv4 } from 'uuid';
import useMediaQuery from '@/hooks/useMediaQuery'
// import { string, oneOfType, node } from 'prop-types'
import Link from '@/utils/Link'
import IconWrapper from '@/utils/IconWrapper'
import TickIcon from '@/icons/tick.svg'
import CrossIcon from '@/icons/delete.svg'
import Button from '@/components/global/Button'
import RichText from '@/utils/RichText'
import styles from './Table.module.css'
import trackingLicensing from '../../../content/tracking-licensing.json'
import hapticsLicensing from '../../../content/haptics-licensing.json'

const onClick = (e, link) => {
	if(!link) {
        e.preventDefault()
        const form = document.querySelector('iframe')
        window.scrollTo({top: (form.offsetTop - 100), behavior: 'smooth'})
    }
}

function TableHead({ children }) {
	return (
		<th className="px-6 py-8 text-xl text-left align-baseline bg-thead font-museoSans">
			{children}
		</th>
	)
}

function TableSubHead({ children }) {
	return (
		<td className="px-6 py-3 text-sm align-baseline border-b font-museoSans border-lightgrey bg-off-white">
			{children}
		</td>
	)
}

function ColumnHeader({ children }) {
	return (
		<td className="px-6 py-4 text-sm align-baseline bg-white border-b border-lightgrey font-museoSans">
			{children}
		</td>
	)
}

function Column({ children, className, ...props }) {
	return (
		<td
			{...props}
			className={classNames(
				'px-6 py-4 text-sm align-baseline bg-white border-b border-lightgrey',
				className
			)}
		>
			{children}
		</td>
	)
}

function Row({ heading, content }) {
	return (
		<div className="flex items-baseline justify-between px-2 py-2 border-b border-lightgrey">
			<h6 className="text-sm font-museoSansBold">{heading}</h6>
			<span className="w-1/2 ml-auto text-sm text-right">{content}</span>
		</div>
	)
}

function MobileView({labels, columns}) {
	return ( 
        <>
            {columns.map(column => {
                const { heading, subheading, cta_link, cta_colour, cta_text, values } = column
                return (
                    <section key={uuidv4()}>
                        <header className="px-2 py-8 border-b border-lightgrey">
                            <h4 className={classNames(
                                cta_colour === 'bg-primary' ? "text-primary" : "text-secondary",
                                "mb-2 text-h4-fluid font-museoSansBold")}
                            >
                                {heading}
                            </h4>
                            {
                                subheading &&
                                <p className="text-sm">
                                    {subheading}
                                </p>
                            }
                        </header>
                        <div className="px-2 py-2 border-b border-lightgrey">
                            <Button
                                key={uuidv4()}
                                as={Link}
                                onClick={e => onClick(e, cta_link)}
                                to={cta_link || "" || ""}
                                className={classNames(cta_colour, "mmx-auto mt-4 mb-4 text-center text-white font-museoSansBold")}
                            >
                                {cta_text}
                            </Button>
                        </div>
                        {labels.map((label,i) => (
                            <Row 
                                key={uuidv4()} 
                                heading={label}
                                content={
                                    !values[i] && <IconWrapper icon={CrossIcon} wrapperClassName="w-4 text-lightgrey ml-auto" /> ||
                                    values[i] === true && <IconWrapper icon={TickIcon} wrapperClassName="w-4 text-primary ml-auto" /> ||
                                    values[i]
                                }
                            />
                        ))}
                    </section>
                )}
            )}
        </>
	)
}

function DesktopView({labels, columns}) {
	return (
		<table className="w-full table-fixed">
			<thead>
				<tr>
					<th>&nbsp;</th>
                    {columns.map(({heading}, i) => <TableHead key={uuidv4()}>{heading}</TableHead>)}
				</tr>
				<tr className="border-b border-lightgrey">
                    {
                        columns.every(({subheading}) => subheading) && (
                            <>
					        <td className="font-museoSans">&nbsp;</td>
                            { columns.map(({subheading}, i) => <TableSubHead key={uuidv4()}>{subheading}</TableSubHead>) }
                            </>
                        )
                    }
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="border-b font-museoSans border-lightgrey">&nbsp;</td>
                    {columns.map(({cta_text, cta_link, cta_colour}, i) => (
                        <td key={uuidv4()} className="px-6 py-3 border-b font-museoSans border-lightgrey">
                            <Button
                                as={Link}
                                onClick={e => onClick(e, cta_link)}
                                to={cta_link || "" || ""}
                                className={classNames(cta_colour, "mx-auto text-white")}
                            >
                                {cta_text}
                            </Button>
                        </td>
                    ))}
				</tr>
                {labels.map((label, i) => (
                    <tr key={uuidv4()}>
                        <ColumnHeader>{label}</ColumnHeader>
                        {columns.map(({values}) => (
                            <Column key={uuidv4()}>
                                {
                                    !values[i] && (<IconWrapper icon={CrossIcon} wrapperClassName="text-lightgrey w-6" />) ||
                                    values[i] === true && (<IconWrapper icon={TickIcon} wrapperClassName="text-primary w-6" />) ||
                                    values[i]
                                }
                            </Column>
                        ))}
                    </tr>
                ))}
				
                <tr>
                    <td className="font-museoSans border-lightgrey">&nbsp;</td>
                    {columns.map(({cta_text, cta_link, cta_colour}, i) => (
                        <td key={uuidv4()} className="px-6 py-8 font-museoSans border-lightgrey">
                            <Button
                                as={Link}
                                onClick={e => onClick(e, cta_link)}
                                to={cta_link || ""}
                                className={classNames(cta_colour, "mx-auto text-white")}
                            >
                                {cta_text}
                            </Button>
                        </td>
                    ))}
				</tr>
			</tbody>
		</table>
	)
}

const contentMap = {
    tracking: trackingLicensing,
    haptics: hapticsLicensing
}

function Table({
    table, intro_text, footer_copy
}) {
    const content = contentMap[table] || trackingLicensing
    
    const { labels, columns } = content

    const isDesktop = useMediaQuery('(min-width: 64em)')
    return (
        <div
            className="wrapper md:pt-24 text-darkgrey py-12"
        >
            <div 
                className={classNames(
                    columns.length < 3 ? "sm:w-2/3" : "sm:w-3/4",
                    "sm:mt-6 sm:mb-6 lg:ml-auto")} 
            >
                <RichText content={intro_text} className={styles.text} />
            </div>
            { isDesktop ? <DesktopView labels={labels} columns={columns} /> : <MobileView labels={labels} columns={columns} /> }
            <div className="w-10/12 mt-8 mb-12 ml-auto text-right font-museoSans">
                { ReactHtmlParser(footer_copy) }
            </div>
        </div>
    )
}

export default Table