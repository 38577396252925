/* eslint-disable react/prop-types */
import React, { useRef } from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'
import ButtonGradient from '@/components/global/ButtonGradient'
import Link from '@/utils/Link'
import Image from '@/utils/Image'
import styles from './ImageVideoHero.module.css';

function ImageVideoHero({
	title,
	video,
	media_type,
	image,
	ctas
}) {
	const ref = useRef()

	return (
		<>
			<div
				ref={ ref }
				className={ classNames('relative',
					{ 'aspect-ratio-5/9 vsm:aspect-ratio-square lg:pb-0 lg:h-70vh': image },
					styles.dropShadow
				) }
			>
				{ media_type === 'video' && video && (
					<div className={ classNames(
						'relative z-10 w-full h-screen',
						styles.videoContainer
					)}>
						<video
							preload="auto"
							loop
							autoPlay
							muted
							playsInline
							src={ video.source_url || video }
							className="object-cover w-full h-full"
						/>
					</div>
				) }
				{ media_type === 'image' && (
					<>
						<Image
							className="object-cover sm:block"
							style={ { height: '100%', width: '100%', position: 'absolute' } }
							{ ...image || image }
						/>
						<div className="absolute inset-0 bg-black-50" />
					</>
				) }
				<div className="wrapper absolute inset-0 z-20 flex flex-col items-left justify-center px-8 mx-auto">
					<h1 className={ classNames(
						styles.title,
						"mb-8 leading-tight text-white text-lg text-h1-fluid font-museoSansBold tracking-wide"
					) }>
						{ title }
					</h1>
					<div>
						{
							ctas && ctas.map(({ cta_link, cta_text, cta_text_colour: textColour, cta_background_colour: bgColour }, i) => (
								cta_link && cta_text &&
								<ButtonGradient
									as={ Link }
									to={ `${ cta_link }` }
									colour={bgColour}
									textColour={textColour}
									className={
										classNames(
											`border-${textColour}`,
											'mr-4 mb-4 text-lg border-2 bg-transparent'
										)
									}
									key={ i }
								>
									{ cta_text }
								</ButtonGradient>
							))
						}
					</div>
				</div>
			</div>
		</>
	)
}

ImageVideoHero.propTypes = {
	title: string,
	// video: ,
	media_type: string,
	// image
}

export default ImageVideoHero
