import React from 'react'
import Slider from 'react-slick'
import classNames from 'classnames'
import Link from '@/utils/Link'
import Image from '@/utils/Image'
import SectionHeading from '@/components/global/SectionHeading'
import IconWrapper from '@/utils/IconWrapper'
import Arrow from '@/icons/upwards-arrow.svg'
import styles from './CaseStudySlider.module.css'

function CaseStudySlider({ related, slider_heading, slider_subheading }) {
	const imageSliderSettings = {
		className: styles.imageSlider,
		infinite: false,
		variableWidth: false,
		centerMode: false,
		speed: 750,
		arrows: false,
		dots: true,
		slidesToShow: 2,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					centerMode: false
				}
			}
		]
	}

	if (!related) return null

	const list = related
		.filter(Boolean)
		.map(
			({
				type,
				blocks,
				path,
				featured_media,
				title,
				wordpress_id,
				...rest
			}) => {
				return {
					wordpress_id,
					title,
					path,
					type,
					label: type === 'post' ? 'News' : 'Case Study',
					image: featured_media
				}
			}
		)

	return (
		<div className="wrapper">
			<div className="relative pb-8 overflow-hidden lg:pb-16">
				<h2 className="mb-4 leading-tight text-primary font-museoSansBold text-h3-fluid">
					{slider_heading}
				</h2>
				<p className="mb-8 text-xl font-san-serif">{slider_subheading}</p>
				<Slider
					fade={false}
					swipeToSlide
					slidesToShow={1}
					{...imageSliderSettings}
				>
					{list.map(
						({ wordpress_id, title, image, path, type, label }, index) => (
							<div
								className={classNames('w-full h-full mr-2 md:mr-8 relative', {
									'md:mt-8 lg:mt-16': index % 2 !== 0
								})}
								key={wordpress_id}
							>
								<Image style={{ width: '100%', height: '100%' }} {...image} />
								<div className="absolute inset-0 z-10 flex flex-col p-4 bg-black-50 hover:bg-primary-90 md:p-8 transition-bg-color">
									<h2 className="w-full mb-auto leading-tight text-white font-museoSansBold text-h3-fluid">
										<Link to={path}>
											<span dangerouslySetInnerHTML={{ __html: title }} />
										</Link>
									</h2>
									<div className="flex items-center justify-between mt-auto">
										<SectionHeading className="mb-0 text-xs text-white">
											{label}
										</SectionHeading>

										<Link
											to={path}
											className="flex-shrink-0 w-10 md:w-16 rotate-90 "
										>
											<IconWrapper
												className="w-full text-white stroke-current"
												label="Read more"
												icon={Arrow}
											/>
										</Link>
									</div>
								</div>
							</div>
						)
					)}
				</Slider>
			</div>
		</div>
	)
}

export default CaseStudySlider
