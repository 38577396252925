import React from 'react'
import classNames from 'classnames'
import Button from '@/components/global/Button'
import Link from '@/utils/Link'

function CTAButtonBlock({
    landingPage,
    cta_button_copy,
    cta_button_link,
    cta_button_colour,
    cta_button_spacing
}) {
    const navigateToForm = () => {
        const form = document.querySelector('#form-block') || document.querySelector('#careers')
        if(form) window.scrollTo({top: (form.offsetTop - 100), behavior: 'smooth'})
    }
    const navigateToHash = (hash) => {
        const element = document.querySelector(hash)
        if(element) window.scrollTo({top: (element.offsetTop - 100), behavior: 'smooth'})
    }

    const handleClick = (e, button_link) => {
        if(!button_link) {
            e.preventDefault();
            return navigateToForm()
        }
        if(button_link.charAt(0) === '#') {
            e.preventDefault();
            return navigateToHash(button_link)
        }
    }


    const button_link = cta_button_link ? cta_button_link.source_url || cta_button_link : ""
    const spacing = cta_button_spacing === 'small' ? "pb-16" : "pb-32"
	return (
		<div className={classNames(
            spacing,
            "flex justify-center"
            )}
        >
			<Button 
                as="div" 
                className="text-lg"
                theme={cta_button_colour || 'primary'}
            >
				<div>
                    <div className="wp-block-button aligncenter">
                        <Link 
                            onClick={e => handleClick(e, button_link)} 
                            to={button_link} 
                            className="wp-block-button__link has-background"
                        >
                            {cta_button_copy}
                        </Link>
                    </div>
                </div>
			</Button>
		</div>
	)
}

export default CTAButtonBlock
