/* eslint-disable react/prop-types */
import React from 'react'
import Link from '@/utils/Link'
import Image from '@/utils/Image'
import RichText from '@/utils/RichText'
import Button from '@/components/global/Button'
import SectionHeading from '@/components/global/SectionHeading'

function ProductBlock({
	product_category,
	product_name,
	product_intro,
	product_image,
	call_to_action_url,
	call_to_action_label
}) {
	return (
		<div className="px-4 lg:px-12">
			<div className="bg-easing-b-how">
				<div className="wrapper">
					<div className="lg:flex lg:items-center py-12 lg:py-24">
						<div className="lg:w-1/2 lg:px-4">
							<Image {...product_image} />
						</div>
						<div className="lg:w-1/2 lg:px-4">
							{product_category && (
								<SectionHeading size="w-20" className="text-sm text-white mb-8">
									{product_category}
								</SectionHeading>
							)}
							<h2 className="text-darkgrey pb-5 lg:pb-6 text-h1-fluid font-museoSansBold leading-none">
								{product_name}
							</h2>
							{product_intro && (
								<RichText
									content={product_intro}
									className="text-white max-w-md mb-4"
								/>
							)}
							{call_to_action_url && call_to_action_label && (
								<Button as={Link} to={call_to_action_url} theme="secondary">
									{call_to_action_label}
								</Button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ProductBlock
