/* eslint-disable react/no-danger */
import React from 'react'

function Paragraph({ content }) {
	return (
		<div
			className="text-lg leading-relaxed text-darkgrey break-words"
			dangerouslySetInnerHTML={{ __html: content }}
		/>
	)
}

export default Paragraph
