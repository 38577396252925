/* eslint-disable react/prop-types */
import React, { useRef } from 'react'
import Slider from 'react-slick'
import classNames from 'classnames'
import 'slick-carousel/slick/slick.css'
import AnimateEntrance from '@/utils/AnimateEntrance'
import Image from '@/utils/Image'
import styles from './Gallery.module.css'
import { textVariants, containerVariants } from '@/utils/transitions'

function Gallery({ heading, heading_colour, gallery }) {
	const settings = {
		infinite: true,
		variableWidth: true,
		centerMode: false,
		speed: 500,
		arrows: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		initialSlide: 0,

		responsive: [
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2
				}
			}
		]
	}

	const imageSlider = useRef()
	if (gallery.length === 0) return null

	return (
		<div className="w-full overflow-hidden">
			<AnimateEntrance
				rootMargin="-200px 0px"
				triggerOnce={false}
				variants={containerVariants}
				onEnter={() => {
					imageSlider.current.slickNext()
				}}
				className="relative py-20 overflow-hidden bg-lightgrey md:py-24 lg:py-24 lg:overflow-visible"
			>
				<Slider {...settings} ref={imageSlider} className={styles.imageSlider}>
					{gallery.map(image => image && (
						<div
							className={classNames(
								styles.slideItem,
								'h-full mr-4 sm:mx-2 focus:outline-none'
							)}
							key={image.id}
							style={{ width: image.width }}
						>
							<Image {...image} />
						</div>
					))}
				</Slider>
			</AnimateEntrance>
		</div>
	)
}

export default Gallery
