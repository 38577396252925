/* eslint-disable react/no-danger, react/prop-types */
import React from 'react'
import classNames from 'classnames'
import { Hero } from '@/components/Hero'
import { HeroShouter } from '@/components/HeroShouter'
import { TextShouter } from '@/components/TextShouter'
import { ImageContentTrio } from '@/components/ImageContentTrio'
import { SingleQuote } from '@/components/Quote'
import { QuoteCollection } from '@/components/QuoteCollection'
// import { BillboardExpanded } from '@/components/Billboard'
import { UseCaseHero } from '@/components/UseCase'
import { CardSlider } from '@/components/CardSlider'
import { Block } from '@/components/Tout'
import ContactForm from '@/components/global/ContactForm'
import { Gallery } from '@/components/Gallery'
import { IconBlock } from '@/components/Icon'
import { SupplierBlock } from '@/components/Supplier'
import { ProductBlock } from '@/components/Product'
import ProductTout from '@/components/ProductTout'
import { OneColumnBlock } from '@/components/Column'
import { RolodexCards } from '@/components/RolodexCards'
// import { ProgramCard } from '@/components/ProgramCard'
import { TimelineBlock } from '@/components/Timeline'
import { PeopleBlock } from '@/components/People'
import Group from '@/components/core/Group'
import CoreButton from '@/components/core/Button'
import CoreHTML from '@/components/core/HTML'
import { Paragraph } from '@/components/core/Paragraph'
import { List } from '@/components/core/List'
import { Columns } from '@/components/core/Columns'
import { Heading } from '@/components/core/Heading'
import { Embed } from '@/components/core/Embed'
import { CaseStudySlider } from '@/components/CaseStudySlider'
import { CaseStudyCollection } from '@/components/CaseStudyCollection'
import Switch, { Case } from '@/utils/Switch'
import PageHeading from '@/components/Tracking/PageHeading'
import { CoreImage } from '@/components/core/Image'
// import PurpleRow from '@/components/hiw/PurpleRow'
import Where2BuyImage from '@/components/Where2Buy/W2BSplitImage'
import Where2BuyLarge from '@/components/Where2Buy/Where2Buy'
import ProductCompare from '@/components/ProductCompare'
import ComparisonTable from '@/components/ComparisonTable'
import HapticsComparisonTable from '@/components/HapticsComparisonTable'
import Logos from '@/components/Logos'
import TextFormBlock from '@/components/TextFormBlock'
import CTAButtonBlock from '@/components/CTAButtonBlock'
import ColumnsBlock from '@/components/ColumnsBlock'
import PullOutBlock from '@/components/PullOutBlock'
import { HrBlock } from '@/components/Bamboo'
import { JobBoardBlock } from '@/components/Greenhouse'
import TabsBlock from '../components/TabsBlock'
import SimpleSlider from '../components/SimpleSlider'
import MultiMediaBlock from '../components/MultiMediaBlock'
import TextImageCtaBlock from '../components/TextImageCtaBlock'
import OptionsBlock from '../components/OptionsBlock'
import ImageVideoHero from '../components/ImageVideoHero'
import TestimonialsBlock from '../components/TestimonialsBlock'
import CTAsBlock from '../components/CTAsBlock'
import PanelsBlock from '../components/PanelsBlock'
import Line from '../components/Line'
import Table from '../components/Table'

function Blocks({ data, media, currentPage = false, onTabSelect = false }) {
	const list = data
	const [firstItem] = data

	if (!firstItem) return null
	/**
	 * for some reason, sometimes the wordpress response is
	 * in the wrong order... If the first item is
	 * the contact form or the first item does not include
	 * a hero component, we can be fairly sure the page
	 * is in the wrong order... Thanks WP.
	 */

	// if (
	// 	firstItem.type === 'acf/form-block' ||
	// 	(typeof firstItem.type === 'string' && !firstItem.type.includes('hero'))
	// ) {
	// 	list = data.reverse()
	// }

	return list.map(({ type, id, licensing, ...block }, index) => (
		<Switch test={ type } key={ index }>
			<Case
				index={ index }
				className={ classNames(
					{ 'hero-block mb-20 content-block md:mb-18 lg:mb-32': !licensing },
					{ 'content-block': licensing }
				) }
				case="acf/hero"
			>
				<Hero { ...block } />
			</Case>

			<Case
				index={ index }
				className="mb-20 content-block md:mb-24 lg:mb-32"
				case="core/content"
			>
				<Group { ...block } />
			</Case>

			<Case
				index={ index }
				className="mb-20 content-block md:mb-18 lg:mb-32"
				case="acf/use-case-hero"
			>
				<UseCaseHero { ...block } />
			</Case>

			<Case
				index={ index }
				className="mb-20 content-block md:mb-18 lg:mb-32"
				case="acf/hero-article"
			>
				<UseCaseHero { ...block } />
			</Case>

			<Case
				index={ index }
				className="mb-20 content-block md:mb-24 lg:mb-32"
				case="acf/case-study-hero"
			>
				<UseCaseHero { ...block } />
			</Case>

			<Case
				index={ index }
				className="mb-20 content-block md:mb-24 lg:mb-32"
				case="acf/logos-block"
			>
				<Logos { ...block } />
			</Case>

			<Case
				index={ index }
				className="mb-20 content-block md:mb-24 lg:mb-32"
				case="acf/case-study-card-slider"
			>
				<CaseStudySlider { ...block } />
			</Case>

			<Case
				index={ index }
				className="mb-20 content-block md:mb-24 lg:mb-32"
				case="acf/casestudy-collection"
			>
				<CaseStudyCollection { ...block } />
			</Case>

			<Case
				index={ index }
				className="content-block"
				case="acf/rolodex-card-block"
			>
				<RolodexCards { ...block } />
			</Case>

			<Case
				index={ index }
				className="mb-20 content-block md:mb-24 lg:mb-32"
				case="acf/quote-collection-block"
			>
				<QuoteCollection { ...block } />
			</Case>

			<Case
				index={ index }
				className="mb-20 content-block md:mb-24 lg:mb-32"
				case="acf/hero-shouter"
			>
				<HeroShouter { ...block } />
			</Case>

			<Case
				index={ index }
				className="mb-20 content-block md:mb-24 lg:mb-32"
				case="acf/text-shouter"
			>
				<TextShouter { ...block } />
			</Case>

			<Case
				index={ index }
				className="mb-20 content-block md:mb-24 lg:mb-32"
				case="acf/image-gallery"
			>
				<Gallery { ...block } />
			</Case>

			<Case
				index={ index }
				className={ classNames(
					{ "pt-5": index === 0 },
					"mb-20 content-block md:mb-24 lg:mb-32"
				) }
				case="acf/heading-image-content-trio"
			>
				<ImageContentTrio { ...block } onTabSelect={ onTabSelect } />
			</Case>

			<Case
				index={ index }
				className="mb-12 content-block md:mb-16 lg:mb-24"
				case="acf/icon-block"
			>
				<IconBlock { ...block } />
			</Case>

			<Case
				index={ index }
				className="mb-20 content-block md:mb-24 lg:mb-32"
				case="acf/supplier-block"
			>
				<SupplierBlock { ...block } />
			</Case>

			<Case
				index={ index }
				className="content-block quote-block"
				case="acf/quote-single"
			>
				<SingleQuote { ...block } />
			</Case>

			<Case
				index={ index }
				className="mb-20 content-block md:mb-24 lg:mb-32"
				case="acf/use-case-card-slider"
			>
				<CardSlider { ...block } />
			</Case>

			<Case
				className="mb-20 content-block md:mb-24 lg:mb-32"
				case="acf/tout-block"
				index={ index }
			>
				<Block { ...block } />
			</Case>

			<Case
				className="mb-20 content-block md:mb-24 lg:mb-32"
				case="acf/tout-block-updated"
				index={ index }
			>
				<Block { ...block } />
			</Case>

			<Case
				className="mb-20 content-block md:mb-24 lg:mb-32"
				case="acf/one-coloumn-block"
				index={ index }
			>
				<OneColumnBlock { ...block } />
			</Case>

			<Case
				className="mb-20 content-block md:mb-24 lg:mb-32"
				case="acf/product-block"
				index={ index }
			>
				<ProductBlock { ...block } />
			</Case>

			<Case
				className="mb-20 content-block md:mb-24 lg:mb-32"
				case="acf/timeline-block"
				index={ index }
			>
				<TimelineBlock { ...block } />
			</Case>

			<Case
				className="mb-20 content-block md:mb-24 lg:mb-32"
				case="acf/our-people-block"
				index={ index }
			>
				<PeopleBlock { ...block } />
			</Case>

			<Case index={ index } className="content-block " case="acf/form-block">
				<ContactForm { ...block } />
			</Case>

			<Case index={ index } className="product-tout" case="acf/product-tout">
				<ProductTout { ...block } />
			</Case>

			<Case index={ index } className="product-tout" case="acf/product-tout-block">
				<ProductTout { ...block } />
			</Case>

			<Case index={ index } case="acf/page-heading">
				<PageHeading { ...block } />
			</Case>

			{/*
			missing props
			<Case
				index={index}
				case="acf/purple-row">
				<PurpleRow {...block} />
			</Case>
			*/}

			<Case index={ index } case="acf/where-to-buy-image">
				<Where2BuyImage { ...block } onTabSelect={ onTabSelect } />
			</Case>

			<Case index={ index } case="acf/where-to-buy">
				<Where2BuyLarge countries={ block.countries } heading={ block.heading } media={ media } />
			</Case>

			<Case index={ index } case="acf/product-comparison-table">
				<ProductCompare { ...block } onTabSelect={ onTabSelect } />
			</Case>


			<Case
				className="max-w-4xl md:px-8 mx-auto content-block core-block"
				case="core-embed"
				index={ index }
			>
				<Embed { ...block } />
			</Case>
			<Case
				className={ classNames(
					{ "wrapper": block.isTabBlock },
					{ "max-w-4xl": !block.isTabBlock },
					"px-8 mx-auto content-block core-block"
				) }
				case="core/heading"
				index={ index }
			>
				<Heading { ...block } />
			</Case>
			<Case
				index={ index }
				className="max-w-4xl md:px-8 mx-auto mb-10 content-block core-block"
				case="core/image"
			>
				<CoreImage { ...block } />
			</Case>
			<Case
				index={ index }
				className="max-w-4xl px-8 mx-auto content-block core-block"
				case="core/button"
			>
				<CoreButton { ...block } />
			</Case>

			<Case
				index={ index }
				className="max-w-4xl px-8 mx-auto content-block core-block"
				case="core/html"
			>
				<CoreHTML { ...block } />
			</Case>

			<Case
				index={ index }
				className={ classNames(
					{ "wrapper": block.isTabBlock },
					{ "max-w-4xl": !block.isTabBlock },
					"px-8 mx-auto content-block core-block child:mb-10"
				) }
				case="core/paragraph"
			>
				<Paragraph { ...block } />
			</Case>

			<Case
				className={ classNames(
					{ "wrapper": block.isTabBlock },
					{ "max-w-4xl": !block.isTabBlock },
					"px-8 mx-auto content-block core-block child:mb-8 list"
				) }
				case="core/list"
				index={ index }
			>
				<List { ...block } />
			</Case>

			<Case
				index={ index }
				className="mb-20  px-8 content-block md:mb-24 lg:mb-32"
				case="core/column"
			>
				<Columns { ...block } />
			</Case>

			<Case index={ index } case="acf/tracking-comparison-table">
				<ComparisonTable { ...block } />
			</Case>

			<Case index={ index } case="acf/haptics-comparison-table">
				<HapticsComparisonTable { ...block } />
			</Case>

			<Case index={ index } case="acf/licensing-table">
				<Table {...block} />
			</Case>

			<Case index={ index } case="acf/text-form-block">
				<TextFormBlock { ...block } />
			</Case>

			<Case index={ index } case="acf/tabs">
				<TabsBlock { ...block } media={ media } currentPage={ currentPage } />
			</Case>

			<Case index={ index } case="acf/cta-button">
				<CTAButtonBlock { ...block } />
			</Case>

			<Case index={ index } case="acf/columns-block">
				<ColumnsBlock { ...block } />
			</Case>

			<Case
				index={ index }
				case="acf/pull-out-block"
				className="mb-20 content-block md:mb-24 lg:mb-32"
			>
				<PullOutBlock { ...block } />
			</Case>

			<Case index={ index } case="acf/bamboo-hr-block">
				<HrBlock { ...block } />
			</Case>

			<Case index={ index } case="acf/greenhouse-job-board-block">
				<JobBoardBlock { ...block } />
			</Case>

			<Case
				index={ index }
				case="acf/multi-media-block"
				className="mb-20 content-block md:mb-24 lg:mb-32"
			>
				<MultiMediaBlock { ...block } />
			</Case>

			<Case
				index={ index }
				case="acf/image-text-cta-block"
				className="mb-0 content-block md:mb-8 lg:mb-12 md:wrapper"
			>
				<TextImageCtaBlock { ...block } />
			</Case>

			<Case
				index={ index }
				case="acf/options-block"
				className="mb-0 content-block md:mb-8 lg:mb-12 md:wrapper"
			>
				<OptionsBlock { ...block } />
			</Case>

			<Case
				index={ index }
				case="acf/image-video-hero"
				className='hero-block mb-20 content-block md:mb-18 lg:mb-32'
			>
				<ImageVideoHero { ...block } />
			</Case>

			<Case
				index={ index }
				case="acf/testimonials"
			>
				<TestimonialsBlock { ...block } />
			</Case>

			<Case
				index={ index }
				case="acf/ctas-block"
			>
				<CTAsBlock { ...block } />
			</Case>

			<Case
				index={ index }
				case="acf/panels-block"
			>
				<PanelsBlock { ...block } />
			</Case>

			<Case
				index={ index }
				className="wrapper py-16"
				case="acf/line"
			>
				<Line { ...block } />
			</Case>

			<Case
				index={ index }
				className="wrapper py-16"
				case="acf/simple-slider"
			>
				<SimpleSlider { ...block } />
			</Case>

		</Switch>
	))
}

export default Blocks
