/* eslint-disable react/prop-types */
import React from 'react'
import BannerTout from './BannerTout'
import FloatingTout from './FloatingTout'

function Block({
	fullwidth,
	background_colour = '#00CF75',
	pre_header,
	tout_image,
	tout_image_type,
	tout_media_type,
	tout_video,
	featured_image,
	tout_header,
	tout_extract,
	tout_cta_label,
	tout_cta_link,
	tout_cta_label_2,
	tout_cta_link_2,
	overlay_alignment,
	list,
	list_header,
	links
}) {
	return (
		<>
			{fullwidth === 'yes' && (
				<BannerTout
					bg={background_colour}
					image={tout_image || featured_image}
					header={pre_header}
					title={tout_header}
					copy={tout_extract}
					btnText={tout_cta_label}
					btnLink={tout_cta_link}
				/>
			)}
			{(fullwidth === 'no' || !fullwidth) && (
				<FloatingTout
					bg={background_colour}
					image_type={tout_image_type || tout_media_type}
					image={tout_image || featured_image}
					video={tout_video}
					header={pre_header}
					title={tout_header}
					copy={tout_extract}
					btnText={tout_cta_label}
					btnLink={tout_cta_link}
					btn2Text={tout_cta_label_2}
					btn2Link={tout_cta_link_2}
					align={overlay_alignment}
					list={list}
					list_header={list_header}
					extraLinks={links}
				/>
			)}
		</>
	)
}

export default Block
