/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React from 'react'
import classNames from 'classnames'
import Image from '@/utils/Image'
import Button from '@/components/global/Button'
import Link from '@/utils/Link'
import styles from './IconBlock.module.css'

function IconBlock({
	icon_set_header,
	heading_colour,
	icons_per_row,
	icon_size,
	icon_set,
	icon_set_copy,
	icon_set_footer,
	icon_link,
	icon_link_text,
	icons
}) {
	return (
		<div className="wrapper">
			<h2
				className={classNames(
					'font-museoSans text-center leading-tight text-h2-fluid',
					heading_colour,
					{ ' mb-10 lg:mb-12 ': !icon_set_copy }
				)}
			>
				{icon_set_header}
			</h2>
			{icon_set_copy && (
				<div className="w-10/12 mx-auto mt-6 mb-10 text-center lg:w-6/12" dangerouslySetInnerHTML={{ __html:icon_set_copy}}>
				</div>
			)}
			<div className="flex flex-col items-center justify-center lg:flex-row lg:items-stretch lg:flex-wrap lg:w-10/12 lg:mx-auto">
				{icons
					.filter(item => item.icon_asset)
					.map(({ icon_copy, icon_asset, icon_link }, index) => (
						<div
							key={index}
							className={classNames(
								'mb-8 lg:mb-0 lg:w-1/3 lg:max-w-xs lg:mb-20 flex-col vsm:flex-row lg:flex-col items-center px-4',
								{ flex: icon_set_header !== 'Our investors' }
							)}
						>
							<div
								className={classNames('mb-6 vsm:mb-0 lg:mb-6 flex-shrink-0', {
									'mx-auto mb-6 w-64 max-w-full h-full items-center flex':
										icon_set_header === 'Our investors',
									'mx-auto w-32 ': icon_set_header !== 'Our investors'
								})}
							>
								{icon_link && icon_asset && <Link to={icon_link}><Image {...icon_asset} /></Link>}
								{!icon_link && icon_asset && <Image {...icon_asset} />}
							</div>
							{icon_copy && (
								<div
									dangerouslySetInnerHTML={{ __html: icon_copy }}
									className={classNames("max-w-xs px-8 text-xs text-center md:text-base lg:text-center", styles.text)}
								/>
							)}
						</div>
					))}
			</div>
			{icon_link && (
				<div className="flex justify-center">
					<Button as={Link} to={icon_link} theme="secondary">
						{icon_link_text}
					</Button>
				</div>
			)}
		</div>
	)
}

export default IconBlock
