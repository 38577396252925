import React from 'react'

function CoreHTML({ content }) {
	return (
		<div className="flex justify-center">
			<div className="w-full" dangerouslySetInnerHTML={{ __html: content }} />
		</div>
	)
}

export default CoreHTML
