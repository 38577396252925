/* eslint-disable react/no-danger */
import React from 'react'
import { string, oneOfType, node } from 'prop-types'
import classNames from 'classnames'
import Link from '@/utils/Link'
import useMediaQuery from '@/hooks/useMediaQuery'
import IconWrapper from '@/utils/IconWrapper'
import TickIcon from '@/icons/tick.svg'
import CrossIcon from '@/icons/delete.svg'
import Button from '@/components/global/Button'

const onClick = e => {
	e.preventDefault()
	const contactForm = document.getElementById('_form_')
	contactForm.scrollIntoView({ behavior: 'smooth' })
}

function Row({ heading, content }) {
	return (
		<div className="flex items-baseline justify-between px-2 py-2 border-b border-lightgrey">
			<h6 className="text-sm font-museoSansBold">{heading}</h6>
			<span className="w-1/2 ml-auto text-sm text-right">{content}</span>
		</div>
	)
}

Row.propTypes = {
	heading: string.isRequired,
	content: oneOfType([string, node]).isRequired
}

function MobileView() {
	return (
		<div>
			<header className="px-2 py-8 border-b border-lightgrey">
				<h4 className="mb-2 text-h4-fluid text-secondary font-museoSansBold">
					Developer
				</h4>
				<p className="text-sm">
					Start creating with our development, and evaluation kits
				</p>
			</header>
			<div className="px-2 py-2 border-b border-lightgrey">
				<h5 className="mb-2 text-h5-fluid font-museoSansBold">Eligibility</h5>
				<p
					className="text-sm"
					dangerouslySetInnerHTML={{
						__html: 'Internal evaluation, development, and demonstration only.'
					}}
				/>
				<Button
					as={Link}
					to="https://developer.ultrahaptics.com/"
					theme="secondary"
					className="mx-auto mt-4 mb-4 text-center text-white font-museoSansBold"
				>
					Get started
				</Button>
			</div>
			<Row heading="Training and support" content="Online only" />
			<Row
				heading="Bespoke design and intergration support"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-lightgrey ml-auto"
						icon={CrossIcon}
					/>
				}
			/>
			<Row
				heading="Public demonstration"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-lightgrey ml-auto"
						icon={CrossIcon}
					/>
				}
			/>
			<Row
				heading="Resellable solution"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-lightgrey ml-auto"
						icon={CrossIcon}
					/>
				}
			/>
			<Row
				heading="Use of Ultraleap name/logo on products**"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-lightgrey ml-auto"
						icon={CrossIcon}
					/>
				}
			/>
			<Row
				heading="Option to white label"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-lightgrey ml-auto"
						icon={CrossIcon}
					/>
				}
			/>
			<Row
				heading="Access to exclusive demos, product roadmaps, detailed product documentation, and UX guidelines"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-lightgrey ml-auto"
						icon={CrossIcon}
					/>
				}
			/>
			<Row
				heading="Priority access to new hardware and software"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-lightgrey ml-auto"
						icon={CrossIcon}
					/>
				}
			/>
			{/* Next set */}

			<header className="px-2 py-8 border-b border-lightgrey">
				<h4 className="mb-2 text-h4-fluid text-primary font-museoSansBold">
					Commercial
				</h4>
				<p className="text-sm">Take your ideas to market</p>
			</header>
			<div className="px-2 py-2 border-b border-lightgrey">
				<h5 className="mb-2 text-h5-fluid font-museoSansBold">Eligibility</h5>
				<p
					className="text-sm"
					dangerouslySetInnerHTML={{
						__html: 'Public demonstrations and commerical rollouts'
					}}
				/>

				<Button
					to="#_form_"
					onClick={onClick}
					theme="primary"
					className="mx-auto mt-4 mb-4 text-center text-white font-museoSansBold"
				>
					Contact us
				</Button>
			</div>
			<Row heading="Training and support" content="Online/onsite" />
			<Row
				heading="Bespoke design and intergration support"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-primary ml-auto"
						icon={TickIcon}
					/>
				}
			/>
			<Row
				heading="Public demonstration"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-primary ml-auto"
						icon={TickIcon}
					/>
				}
			/>
			<Row
				heading="Resellable solution"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-primary ml-auto"
						icon={TickIcon}
					/>
				}
			/>
			<Row
				heading="Use of Ultraleap name/logo on products**"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-primary ml-auto"
						icon={TickIcon}
					/>
				}
			/>
			<Row
				heading="Option to white label"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-primary ml-auto"
						icon={TickIcon}
					/>
				}
			/>
			<Row
				heading="Access to exclusive demos, product roadmaps, detailed product documentation, and UX guidelines"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-primary ml-auto"
						icon={TickIcon}
					/>
				}
			/>
			<Row
				heading="Priority access to new hardware and software"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-primary ml-auto"
						icon={TickIcon}
					/>
				}
			/>
		</div>
	)
}

function TableHead({ children }) {
	return (
		<th className="px-6 py-8 text-xl text-left align-baseline bg-thead font-museoSans">
			{children}
		</th>
	)
}

TableHead.propTypes = {
	children: oneOfType([string, node]).isRequired
}

function TableSubHead({ children }) {
	return (
		<td className="px-6 py-3 text-sm align-baseline border-b font-museoSans border-lightgrey bg-off-white">
			{children}
		</td>
	)
}

TableSubHead.propTypes = {
	children: oneOfType([string, node]).isRequired
}

function ColumnHeader({ children }) {
	return (
		<td className="px-6 py-4 text-sm align-baseline bg-white border-b border-lightgrey font-museoSans">
			{children}
		</td>
	)
}

ColumnHeader.propTypes = {
	children: oneOfType([string, node]).isRequired
}

function Column({ children, className, ...props }) {
	return (
		<td
			{...props}
			className={classNames(
				'px-6 py-4 text-sm align-baseline bg-white border-b border-lightgrey',
				className
			)}
		>
			{children}
		</td>
	)
}

Column.propTypes = {
	children: oneOfType([string, node]),
	className: string
}

function DesktopView() {
	return (
		<table className="w-full table-fixed">
			<thead>
				<tr>
					<th>&nbsp;</th>
					<TableHead>Developer</TableHead>
					<TableHead>Commercial</TableHead>
				</tr>
				<tr>
					<td className="border-b font-museoSans border-lightgrey">&nbsp;</td>
					<TableSubHead>
						Start creating with our development, and evaluation kits
					</TableSubHead>
					<TableSubHead>Take your ideas to market</TableSubHead>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="px-6 py-4 text-sm align-baseline bg-white border-t border-lightgrey font-museoSans">
						Eligibility
					</td>
					<td className="px-6 py-3 text-sm align-baseline border-t font-museoSans border-lightgrey">
						Internal evaluation, development, and demonstration only
					</td>
					<td className="px-6 py-3 text-sm align-baseline border-t font-museoSans border-lightgrey">
						Public demonstrations and commercial rollouts
					</td>
				</tr>
				<tr>
					<td className="border-b font-museoSans border-lightgrey">&nbsp;</td>
					<td className="px-6 py-3 border-b font-museoSans border-lightgrey">
						<Button
							as={Link}
							to="https://developer.ultrahaptics.com/"
							theme="secondary"
							className="mx-auto text-white"
						>
							Get started
						</Button>
					</td>
					<td className="px-6 py-3 border-b font-museoSans border-lightgrey">
						<Button
							to="#0"
							onClick={onClick}
							theme="primary"
							className="mx-auto text-white"
						>
							Contact us
						</Button>
					</td>
				</tr>
				<tr>
					<ColumnHeader>Training and support</ColumnHeader>
					<Column>Online only</Column>
					<Column>Online/onsite</Column>
				</tr>

				<tr>
					<ColumnHeader>Bespoke design and integration support</ColumnHeader>
					<Column className="">
						<IconWrapper
							icon={CrossIcon}
							wrapperClassName="text-lightgrey w-6"
						/>
					</Column>
					<Column className="">
						<IconWrapper icon={TickIcon} wrapperClassName="text-primary w-6" />
					</Column>
				</tr>
				<tr>
					<ColumnHeader>Public demonstration</ColumnHeader>
					<Column className="">
						<IconWrapper
							icon={CrossIcon}
							wrapperClassName="text-lightgrey w-6"
						/>
					</Column>
					<Column className="">
						<IconWrapper icon={TickIcon} wrapperClassName="text-primary w-6" />
					</Column>
				</tr>
				<tr>
					<ColumnHeader>Resellable solution</ColumnHeader>
					<Column className="">
						<IconWrapper
							icon={CrossIcon}
							wrapperClassName="text-lightgrey w-6"
						/>
					</Column>
					<Column className="">
						<IconWrapper icon={TickIcon} wrapperClassName="text-primary w-6" />
					</Column>
				</tr>
				<tr>
					<ColumnHeader>Use of Ultraleap name/logo on products**</ColumnHeader>
					<Column className="">
						<IconWrapper
							icon={CrossIcon}
							wrapperClassName="text-lightgrey w-6"
						/>
					</Column>
					<Column className="">
						<IconWrapper icon={TickIcon} wrapperClassName="text-primary w-6" />
					</Column>
				</tr>
				<tr>
					<ColumnHeader>Option to white label</ColumnHeader>
					<Column className="">
						<IconWrapper
							icon={CrossIcon}
							wrapperClassName="text-lightgrey w-6"
						/>
					</Column>
					<Column className="">
						<IconWrapper icon={TickIcon} wrapperClassName="text-primary w-6" />
					</Column>
				</tr>
				<tr>
					<ColumnHeader>
						Access to exclusive demos, product roadmaps, detailed product
						documentation, and UX guidelines
					</ColumnHeader>
					<Column className="">
						<IconWrapper
							icon={CrossIcon}
							wrapperClassName="text-lightgrey w-6"
						/>
					</Column>
					<Column className="">
						<IconWrapper icon={TickIcon} wrapperClassName="text-primary w-6" />
					</Column>
				</tr>
				<tr>
					<ColumnHeader>
						Priority access to new hardware and software
					</ColumnHeader>
					<Column className="">
						<IconWrapper
							icon={CrossIcon}
							wrapperClassName="text-lightgrey w-6"
						/>
					</Column>
					<Column className="">
						<IconWrapper icon={TickIcon} wrapperClassName="text-primary w-6" />
					</Column>
				</tr>
				<tr>
					<td className="font-museoSans border-lightgrey">&nbsp;</td>
					<td className="px-6 py-8 font-museoSans border-lightgrey">
						<Button
							as={Link}
							to="https://developer.ultrahaptics.com/"
							theme="secondary"
							className="mx-auto text-white"
						>
							Get started
						</Button>
					</td>
					<td className="px-6 py-8 font-museoSans border-lightgrey">
						<Button
							to="#0"
							onClick={onClick}
							theme="primary"
							className="mx-auto text-white"
						>
							Contact us
						</Button>
					</td>
				</tr>
			</tbody>
		</table>
	)
}

function HapticsComparisonTable({ className }) {
	const isDesktop = useMediaQuery('(min-width: 64em)')

	return (
		<div
			className={classNames(className, 'wrapper md:pt-24 text-darkgrey py-12')}
		>
			<div className="sm:w-3/4 sm:mt-6 sm:mb-6 lg:ml-auto">
				<p className="font-museoSans">
					All users developing with Ultraleap haptics hardware and/or software
					need a license.* Choose the right one for you.
				</p>
				<p className="pt-6 font-museoSans">
					Developer licenses for our haptics technology are free. External
					demonstrations and rollouts require a commercial license.
				</p>
			</div>
			{isDesktop ? <DesktopView /> : <MobileView />}
			<div className="w-10/12 mt-8 mb-12 ml-auto text-right font-museoSans">
				<p>
					* For detailed information please refer to our{' '}
					<a
						href="https://developer.ultrahaptics.com/end-user-license-agreement/"
						target="_blank"
						rel="noopener noreferrer"
					>
						SDK agreement
					</a>
				</p>
				<p>** Subject to approval and brand guidelines</p>
			</div>
			<div className="mr-auto leading-normal sm:w-10/12 lg:w-8/12 font-museoSans">
				<h3 className="mb-6 text-h4-fluid text-primary">
					Haptics Partner Program <br />
					Accelerate your research and development
				</h3>
				<p className="mb-6">
					For additional support in developing use-cases, interaction design,
					and hardware and software integration, join our Haptics Partner
					Program. Members can draw on the expertise of our world-leading team
					of haptics and 3D interaction design experts.
				</p>
				<p className="mb-6">
					Our team have mastered the exact science of integrating haptics into
					applications. To catalyse innovation, deliver great products fast, and
					get it right first time, partner with us.{' '}
				</p>

				<p className="mb-6">
					We shape the level of partnership around your needs. Some things that
					can be included are:
				</p>
				<ul className="pb-4 pl-4 list-disc">
					<li>Exploration, development, and testing, of use-cases</li>
					<li>Access to SDK/API expertise</li>
					<li>Exclusive plug-and-play demos</li>
					<li>Bespoke sensation design</li>
					<li>Haptic and gesture design/UX guidelines</li>
					<li>Bespoke on-site training</li>
					<li>Haptic and interaction design support</li>
					<li>Hardware and software integration support</li>
					<li>Custom array design</li>
					<li>Public demonstration licensing options for concepts</li>
					<li>Detailed product documentation, and product roadmaps</li>
					<li>Priority access to new hardware and software</li>
				</ul>
				<p>
					You’ll be in good company. We’re already working with leading product
					companies, agencies, and software firms.
				</p>
			</div>
		</div>
	)
}

HapticsComparisonTable.propTypes = {
	className: string
}

export default HapticsComparisonTable
