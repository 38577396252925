/* eslint-disable react/prop-types */
import React from 'react'
import { string, array } from 'prop-types'
import classNames from 'classnames'
import Link from '@/utils/Link'
import Button from '@/components/global/Button'
import Image from '@/utils/Image'
import RichText from '@/utils/RichText'
import SectionHeading from '@/components/global/SectionHeading'
import {
	imageVariants,
	textVariants,
	containerSwipeVariants
} from '@/utils/transitions'
import styles from './FloatingTout.module.css'

function FloatingTout({
	bg,
	image,
	image_type,
	video,
	header,
	title,
	copy,
	btnText,
	btnLink,
	btn2Text,
	btn2Link,
	align,
	extraLinks
}) {
	const isExtraLinksValid = !!(Array.isArray(extraLinks) && extraLinks.length && extraLinks[0].tout_cta_link)

	const btns = [{text: btnText, link: btnLink}, {text: btn2Text, link: btn2Link}]

	return (
		<div className="px-4 md:px-8 md:wrapper">
			<div
				className={ classNames(
					'lg:mx-auto relative overflow-hidden lg:flex lg:items-center lg:py-16',
					{
						'justify-start': align === 'Left'
					}
				) }
			>
				<section
					name="tout-image"
					className={ classNames('w-full lg:w-3/4 lg:absolute h-full', {
						'right-0': align === 'Left',
						'left-0': align !== 'Left'
					}) }
				>
					{ (!image_type || image_type.toLowerCase() === 'image') && image && (
						<Image style={ { height: '100%', width: '100%' } } { ...image } />
					) }
					{ image_type === 'Video' && video && (
						<video
							preload="auto"
							loop
							autoPlay
							muted
							playsInline
							src={ video.source_url }
							className="object-cover w-full h-full"
						/>
					) }
				</section>
				<div
					className={ classNames(
						'lg:relative z-10 lg:w-1/2 h-full flex items-stretch',
						{
							'left-0': align === 'Left',
							'left-1/2': align !== 'Left'
						}
					) }
				>
					<section
						style={ { backgroundColor: bg } }
						className="w-full p-8 lg:p-12 xl:p-24"
						name="tout-content"
					// triggerOnce={false}
					>
						{ header && (
							<SectionHeading className="mb-12 text-xs text-white" size="w-20">
								{ header }
							</SectionHeading>
						) }
						{ title && (
							<h3
								className="mb-6 leading-tight text-white text-h3-fluid font-museoSansRegular"
								dangerouslySetInnerHTML={ { __html: title.replace(/\s(?=[^\s]*$)/g, "&nbsp;") } }
							/>
						) }

						<div initial="hide" variants={ textVariants }>
							{ copy && <RichText content={ copy } className={ classNames('mb-8 text-white', styles.copy) } /> }
						</div>

						<div className="flex flex-col flex-wrap vsm:flex-row sm:items-center justifty-center md:justify-left">
							{ (btns[0].link || btns[1].link) && btns.map(({text, link}, i) => {
								return !link && !text 
									? null 
									: 
									<Button
										as={ Link }
										to={ link }
										theme="white"
										key={i}
										className="my-2 mr-4"
										style={ { color: bg, border: '2px solid transparent' } }
									>
										{ text }
									</Button>
							})}
							{ extraLinks && isExtraLinksValid &&
								extraLinks.map(link => (
									<div
										key={ link.id }
										className="vsm:ml-6"
									>
										<Button
											as={ Link }
											to={ link.tout_cta_link }
											theme="transparent"
											className="my-2"
											key={ link.id }
										>
											{ link.tout_cta_label }
										</Button>
									</div>
								)) }
						</div>
					</section>
				</div>
			</div>
		</div>
	)
}

FloatingTout.propTypes = {
	bg: string,
	// image: imageType,
	header: string,
	title: string,
	copy: string,
	btnText: string,
	btnLink: string,
	align: string,
	extraLinks: array

}

export default FloatingTout
