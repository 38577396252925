/* eslint-disable react/prop-types */
import React from 'react'
import { take } from 'ramda'
import { useStickyroll } from '@stickyroll/hooks'

import { motion, transform } from 'framer-motion'
import classNames from 'classnames'
import { mapSlideItems } from '@/utils'
import Image from '@/utils/Image'
import RichText from '@/utils/RichText'

const setStyle = ({ index, currentPage, style, initial, end }) => {
	const prop =
		index === currentPage && index > 0
			? style
			: index > currentPage - 1
			? initial
			: end

	return prop
}

const setScale = ({ index, currentPage, progress, style, initial, end }) => {
	const prop =
		currentPage === 1 && progress === 0
			? initial
			: progress > 0 && currentPage === index + 1
			? style
			: index > currentPage - 1
			? initial
			: end

	return prop
}

const setOpacity = ({ index, currentPage, progress, enter, exit }) => {
	const prop =
		currentPage === 1 && progress === 0 && currentPage === index + 1
			? 1
			: currentPage === index
			? enter
			: currentPage < index
			? 0
			: exit

	return prop
}

// const threshold = Array.from({ length: 100 }, (_, i) => (i + 1) / 100)
function RolodexCard({
	id,
	card_asset,
	card_title,
	card_description,
	progress,
	index,
	currentPage
}) {
	const y = transform(progress, [0, 1], ['120%', '0%']) || 0
	const scale = transform(progress, [0, 1], [1, 0]) || 0
	const opacity = transform(progress, [0, 0.2], [0, 1]) || 0
	const exitOpacity = transform(progress, [0, 1], [1, 0]) || 0

	return (
		<motion.div
			key={id}
			className={classNames(
				'aspect-ratio-1/2 vsm:aspect-ratio-5/9 lg:aspect-ratio-16/9 not-last:mb-6',
				{ relative: index === 0, 'absolute inset-0 w-full': index !== 0 }
			)}
			style={{
				y: setStyle({
					index,
					currentPage,
					style: y,
					initial: '120%',
					end: '0%'
				}),
				scale: setScale({
					index,
					currentPage,
					style: scale,
					progress,
					initial: 1,
					end: 0
				}),
				opacity: setOpacity({
					index,
					currentPage,
					enter: opacity,
					exit: exitOpacity,
					progress
				})
			}}
		>
			<div className="absolute inset-0">
				<div className="absolute inset-0">
					<Image
						{...card_asset}
						style={{ width: '100%', height: '100%', background: '#444' }}
					/>
					<span className="absolute inset-0 z-20 block w-full h-full bg-black-50" />
				</div>
				<div className="relative z-20 flex flex-col items-center justify-center h-full px-6 py-12 mx-auto sm:justify-end lg:w-8/12">
					<h3 className="mb-4 leading-tight text-center text-white font-museoSans text-h3-fluid">
						{card_title}
					</h3>
					<RichText
						content={card_description}
						className="text-center text-white"
					/>
				</div>
			</div>
		</motion.div>
	)
}

function RolodexList({ rolodex_heading, cardList, pagesToAnimation }) {
	const [
		wrapper,
		{ currentPage, pageCount, pageIndex, progress }
	] = useStickyroll({
		pages: pagesToAnimation
	})

	return (
		<div className="relative py-16 wrapper">
			<h2 className="mb-8 leading-tight text-center vsm:hidden text-secondary font-museoSansBold text-h2-fluid">
				{rolodex_heading}
			</h2>
			<div
				className="relative"
				ref={wrapper}
				style={{ height: `${pageCount * 100}vh` }}
			>
				<div className="sticky top-8 md:top-24">
					<h2 className="relative hidden pt-16 mb-8 leading-tight text-center vsm:block text-secondary font-museoSansBold text-h2-fluid">
						{rolodex_heading}
					</h2>
					<div className="relative w-full max-w-sm mx-auto lg:max-w-4xl">
						{cardList.map(
							({ card_title, card_asset, card_description }, index) => (
								<RolodexCard
									key={card_title}
									card_title={card_title}
									card_asset={card_asset}
									card_description={card_description}
									progress={progress}
									currentPage={currentPage}
									pageIndex={pageIndex}
									index={index}
								/>
							)
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

function RolodexCards({ rolodex_heading, card, ...cards }) {
	const cardList = mapSlideItems('card_', 3)(cards)
	const { length } = cardList
	const pagesToAnimation = take(length, cardList)

	return (
		<RolodexList
			rolodex_heading={rolodex_heading}
			cardList={cardList}
			pagesToAnimation={pagesToAnimation}
		/>
	)
}

export default RolodexCards
