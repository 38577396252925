import React from 'react'

function Heading({ content }) {
	const headingLevel = content[2];
	return (
		<div
			className={`mb-6 mt-6 leading-tight text-primary text-h${headingLevel}-fluid font-ginger`}
			dangerouslySetInnerHTML={{ __html: content }}
		/>
	)
}

export default Heading
