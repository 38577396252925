/* eslint-disable react/no-danger */
import React from 'react'
import { string, oneOfType, node } from 'prop-types'
import classNames from 'classnames'
import Link from '@/utils/Link'
import useMediaQuery from '@/hooks/useMediaQuery'
import IconWrapper from '@/utils/IconWrapper'
import TickIcon from '@/icons/tick.svg'
import CrossIcon from '@/icons/delete.svg'
import Button from '@/components/global/Button'

const onClick = e => {
	e.preventDefault()
	const contactForm = document.getElementById('_form_')
	contactForm.scrollIntoView({ behavior: 'smooth' })
}

function Row({ heading, content }) {
	return (
		<div className="flex items-baseline justify-between px-2 py-2 border-b border-lightgrey">
			<h6 className="text-sm font-museoSansBold">{heading}</h6>
			<span className="w-1/2 ml-auto text-sm text-right">{content}</span>
		</div>
	)
}

Row.propTypes = {
	heading: string.isRequired,
	content: oneOfType([string, node]).isRequired
}

function MobileView() {
	return (
		<div>
			<header className="px-2 py-8 border-b border-lightgrey">
				<h4 className="mb-2 text-h4-fluid text-secondary font-museoSansBold">
					Developer
				</h4>
				<p className="text-sm">Start creating with the free version </p>
			</header>
			<div className="px-2 py-2 border-b border-lightgrey">
				<h5 className="mb-2 text-h5-fluid font-museoSansBold">Eligibility</h5>
				<p
					className="text-sm"
					dangerouslySetInnerHTML={{
						__html: 'Non-commercial use only'
					}}
				/>
				<Button
					as={Link}
					to="https://developer.leapmotion.com/"
					theme="secondary"
					className="mx-auto mt-4 mb-4 text-white"
				>
					Get started
				</Button>
			</div>
			<Row heading="Training and support" content="Online only" />
			<Row
				heading="Engineering support"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-lightgrey ml-auto"
						icon={CrossIcon}
					/>
				}
			/>
			<Row
				heading="Public demonstration"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-lightgrey ml-auto"
						icon={CrossIcon}
					/>
				}
			/>
			<Row
				heading="Resellable solution"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-lightgrey ml-auto"
						icon={CrossIcon}
					/>
				}
			/>
			<Row
				heading="Use of Ultraleap name/logo on products**"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-lightgrey ml-auto"
						icon={CrossIcon}
					/>
				}
			/>
			<Row
				heading="Option to white label"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-lightgrey ml-auto"
						icon={CrossIcon}
					/>
				}
			/>

			{/* Next set */}

			<header className="px-2 py-8 border-b border-lightgrey">
				<h4 className="mb-2 text-h4-fluid text-primary font-museoSansBold">
					Commercial
				</h4>
				<p className="text-sm">Resellable solutions for larger businesses</p>
			</header>
			<div className="px-2 py-2 pt-4 pb-4 border-b border-lightgrey">
				<h5 className="mb-2 text-h5-fluid font-museoSansBold">Eligibility</h5>
				<p
					className="text-sm"
					dangerouslySetInnerHTML={{
						__html:
							'Businesses embedding Ultraleap tracking hardware and/or software in a resellable product'
					}}
				/>
				<p
					className="mt-2 text-sm"
					dangerouslySetInnerHTML={{
						__html:
							'Any business developing an application for industrial, military, commercial or medical equipment, or CAD'
					}}
				/>
				<Button
					to="#_form_"
					onClick={onClick}
					theme="primary"
					className="mx-auto mt-4 text-white"
				>
					Contact us
				</Button>
			</div>
			<Row heading="Training and support" content="Online/onsite" />
			<Row heading="Engineering support" content="By separate agreement" />
			<Row
				heading="Public demonstration"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-primary ml-auto"
						icon={TickIcon}
					/>
				}
			/>
			<Row
				heading="Resellable solution"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-primary ml-auto"
						icon={TickIcon}
					/>
				}
			/>
			<Row
				heading="Use of Ultraleap name/logo on products**"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-primary ml-auto"
						icon={TickIcon}
					/>
				}
			/>
			<Row
				heading="Option to white label"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-primary ml-auto"
						icon={TickIcon}
					/>
				}
			/>

			{/* Next set */}

			<header className="px-2 py-8 border-b border-lightgrey">
				<h4 className="mb-2 text-h4-fluid text-primary font-museoSansBold">
					Enterprise
				</h4>
				<p className="text-sm">Internal business use</p>
			</header>
			<div className="px-2 py-2 pt-4 pb-4 border-b border-lightgrey">
				<h5 className="mb-2 text-h5-fluid font-museoSansBold">Eligibility</h5>
				<p
					className="text-sm"
					dangerouslySetInnerHTML={{
						__html:
							'Applications for internal business use only (e.g. CAD or productivity tools)'
					}}
				/>
				<Button
					to="#_form_"
					onClick={onClick}
					theme="primary"
					className="mx-auto mt-4 text-white"
				>
					Contact us
				</Button>
			</div>
			<Row heading="Training and support" content="Online/onsite" />
			<Row heading="Engineering support" content="By seperate agreement" />
			<Row
				heading="Public demonstration"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-lightgrey ml-auto"
						icon={CrossIcon}
					/>
				}
			/>
			<Row
				heading="Resellable solution"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-lightgrey ml-auto"
						icon={CrossIcon}
					/>
				}
			/>
			<Row
				heading="Use of Ultraleap name/logo on products**"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-primary ml-auto"
						icon={TickIcon}
					/>
				}
			/>
			<Row
				heading="Option to white label"
				content={
					<IconWrapper
						wrapperClassName="w-4 text-lightgrey ml-auto"
						icon={CrossIcon}
					/>
				}
			/>
		</div>
	)
}

function TableHead({ children }) {
	return (
		<th className="px-6 py-8 text-xl text-left align-baseline bg-thead font-museoSans">
			{children}
		</th>
	)
}

TableHead.propTypes = {
	children: oneOfType([string, node]).isRequired
}

function TableSubHead({ children }) {
	return (
		<td className="px-6 py-3 text-sm align-baseline border-b font-museoSans border-lightgrey bg-off-white">
			{children}
		</td>
	)
}

TableSubHead.propTypes = {
	children: oneOfType([string, node]).isRequired
}

function ColumnHeader({ children }) {
	return (
		<td className="px-6 py-4 text-sm align-baseline bg-white border-b border-lightgrey font-museoSans">
			{children}
		</td>
	)
}

ColumnHeader.propTypes = {
	children: oneOfType([string, node]).isRequired
}

function Column({ children, className, ...props }) {
	return (
		<td
			{...props}
			className={classNames(
				'px-6 py-4 text-sm align-baseline bg-white border-b border-lightgrey',
				className
			)}
		>
			{children}
		</td>
	)
}

Column.propTypes = {
	children: oneOfType([string, node]),
	className: string
}

function DesktopView() {
	return (
		<table className="w-full table-fixed">
			<thead>
				<tr>
					<th>&nbsp;</th>
					<TableHead>Developer</TableHead>
					<TableHead>Commercial</TableHead>
					<TableHead>Enterprise</TableHead>
				</tr>
				<tr>
					<td className="border-b font-museoSans border-lightgrey">&nbsp;</td>
					<TableSubHead>Start creating with the free version</TableSubHead>
					<TableSubHead>
						Resellable solutions for larger businesses
					</TableSubHead>
					<TableSubHead>Internal business use</TableSubHead>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="px-6 py-4 text-sm align-baseline bg-white border-t border-lightgrey font-museoSans">
						Eligibility
					</td>
					<td className="px-6 py-3 text-sm align-baseline border-t font-museoSans border-lightgrey">
						<ul className="list-disc">
							<li>Non-commercial use only</li>
						</ul>
					</td>
					<td className="px-6 py-3 text-sm align-baseline border-t font-museoSans border-lightgrey">
						<ul className="list-disc">
							<li>
								Businesses embedding Ultraleap tracking hardware and/or software
								in a resellable product{' '}
							</li>
							<li>
								Any business developing an application for industrial, military,
								commercial or medical equipment, or CAD
							</li>
						</ul>
					</td>
					<td className="px-6 py-3 text-sm align-baseline border-t font-museoSans border-lightgrey">
						<ul className="list-disc">
							<li>
								Applications for internal business use only (e.g. CAD or
								productivity tools)
							</li>
						</ul>
					</td>
				</tr>
				<tr>
					<td className="border-b font-museoSans border-lightgrey">&nbsp;</td>
					<td className="px-6 py-3 border-b font-museoSans border-lightgrey">
						<Button
							as={Link}
							to="https://developer.leapmotion.com/"
							theme="secondary"
							className="mx-auto text-white"
						>
							Get started
						</Button>
					</td>
					<td className="px-6 py-3 border-b font-museoSans border-lightgrey">
						<Button
							to="#_form_"
							onClick={onClick}
							theme="primary"
							className="mx-auto text-white"
						>
							Contact us
						</Button>
					</td>
					<td className="px-6 py-3 border-b font-museoSans border-lightgrey">
						<Button
							to="#_form_"
							onClick={onClick}
							theme="primary"
							className="mx-auto text-white"
						>
							Contact us
						</Button>
					</td>
				</tr>
				<tr>
					<ColumnHeader>Training and support</ColumnHeader>
					<Column>Online only</Column>
					<Column>Online/onsite</Column>
					<Column>Online/onsite</Column>
				</tr>

				<tr>
					<ColumnHeader>Engineering support</ColumnHeader>
					<Column className="">
						<IconWrapper
							icon={CrossIcon}
							wrapperClassName="text-lightgrey w-6"
						/>
					</Column>
					<Column className="">By separate agreement</Column>
					<Column className="">By separate agreement</Column>
				</tr>
				<tr>
					<ColumnHeader>Public demonstration</ColumnHeader>
					<Column className="">
						<IconWrapper
							icon={CrossIcon}
							wrapperClassName="text-lightgrey w-6"
						/>
					</Column>
					<Column className="">
						<IconWrapper icon={TickIcon} wrapperClassName="text-primary w-6" />
					</Column>
					<Column className="">
						<IconWrapper
							icon={CrossIcon}
							wrapperClassName="text-lightgrey w-6"
						/>
					</Column>
				</tr>
				<tr>
					<ColumnHeader>Resellable solution</ColumnHeader>
					<Column className="">
						<IconWrapper
							icon={CrossIcon}
							wrapperClassName="text-lightgrey w-6"
						/>
					</Column>
					<Column className="">
						<IconWrapper icon={TickIcon} wrapperClassName="text-primary w-6" />
					</Column>
					<Column className="">
						<IconWrapper
							icon={CrossIcon}
							wrapperClassName="text-lightgrey w-6"
						/>
					</Column>
				</tr>
				<tr>
					<ColumnHeader>Use of Ultraleap name/logo on products**</ColumnHeader>
					<Column className="">
						<IconWrapper
							icon={CrossIcon}
							wrapperClassName="text-lightgrey w-6"
						/>
					</Column>
					<Column className="">
						<IconWrapper icon={TickIcon} wrapperClassName="text-primary w-6" />
					</Column>
					<Column className="">
						<IconWrapper icon={TickIcon} wrapperClassName="text-primary w-6" />
					</Column>
				</tr>
				<tr>
					<ColumnHeader>Option to white label</ColumnHeader>
					<Column className="">
						<IconWrapper
							icon={CrossIcon}
							wrapperClassName="text-lightgrey w-6"
						/>
					</Column>
					<Column className="">
						<IconWrapper icon={TickIcon} wrapperClassName="text-primary w-6" />
					</Column>
					<Column className="">
						<IconWrapper
							icon={CrossIcon}
							wrapperClassName="text-lightgrey w-6"
						/>
					</Column>
				</tr>
				<tr>
					<td className="font-museoSans border-lightgrey">&nbsp;</td>
					<td className="px-6 py-8 font-museoSans border-lightgrey">
						<Button
							as={Link}
							to="https://developer.ultrahaptics.com/"
							theme="secondary"
							className="mx-auto mb-4 text-center text-white font-museoSansBold"
						>
							Get started
						</Button>
					</td>
					<td className="px-6 py-8 font-museoSans border-lightgrey">
						<Button
							to="#_form_"
							onClick={onClick}
							theme="primary"
							className="mx-auto mb-8 text-center text-white font-museoSansBold"
						>
							Contact us
						</Button>
					</td>
					<td className="px-6 py-8 font-museoSans border-lightgrey">
						<Button
							to="#_form_"
							onClick={onClick}
							theme="primary"
							className="mx-auto mb-8 text-center text-white font-museoSansBold"
						>
							Contact us
						</Button>
					</td>
				</tr>
			</tbody>
		</table>
	)
}

function ComparisonTable({ className }) {
	const isDesktop = useMediaQuery('(min-width: 64em)')

	return (
		<div
			className={classNames(className, 'wrapper md:pt-24 text-darkgrey py-12')}
		>
			<div className="mb-12 sm:w-3/4 lg:ml-auto">
				<p className="font-museoSans">
					All customers developing with the Leap Motion Controller need a
					license.* Choose the right one for you.
				</p>
			</div>
			{isDesktop ? <DesktopView /> : <MobileView />}
			<div className="w-10/12 pt-8 mt-4 mb-12 ml-auto text-right font-museoSans">
				<p>
					* For detailed information please refer to our{' '}
					<a
						href="https://central.leapmotion.com/agreements/SdkAgreement"
						target="_blank"
						rel="noopener noreferrer"
					>
						SDK agreement
					</a>
				</p>
				<p>** Subject to approval and brand guidelines</p>
			</div>
		</div>
	)
}

ComparisonTable.propTypes = {
	className: string
}

export default ComparisonTable
