import React from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'
import RichText from '@/utils/RichText'
import AnimateEntrance from '@/utils/AnimateEntrance'
import styles from './Quote.module.css'

function SingleQuote({ quote, quoter, creditcompany }) {
	return (
		<div className="py-20 text-white bg-primary md:py-24 lg:py-32">
			<AnimateEntrance rootMargin="-25% 0px" className="max-w-3xl px-8 mx-auto">
				<RichText
					className={classNames(
						'text-h3-fluid leading-tight font-museoSans relative mb-4 md:mb-8 leading-snug',
						styles.quote
					)}
					content={quote}
				/>
				<cite>
					{quoter} - {creditcompany}
				</cite>
			</AnimateEntrance>
		</div>
	)
}

SingleQuote.propTypes = {
	quote: string,
	quoter: string,
	creditcompany: string
}

export default SingleQuote
