/* eslint-disable react/no-danger */
import React from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'
import styles from './RichText.module.css'
import Node from '../Node'

function RichText({ content, as = 'div', className }) {
	return (
		<Node
			as={as}
			className={classNames(styles.text, className)}
			dangerouslySetInnerHTML={{ __html: content }}
		/>
	)
}

RichText.propTypes = {
	content: string.isRequired,
	as: string,
	className: string
}

export default RichText
