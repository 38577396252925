/* eslint-disable react/prop-types */
import React from 'react'
import { string } from 'prop-types'
import ButtonGradient from '@/components/global/ButtonGradient'
import Link from '@/utils/Link'
// import styles from './ImageVideoHero.module.css';

function CTAsBlock({
	title,
    copy,
    ctas
}) {
	return (	
        <div className="wrapper inset-0 z-20 flex flex-col items-left justify-center px-8 mx-auto">
            {title && <h2 className="text-darkgrey text-h2-fluid font-museoSans leading-tight text-center pb-8">{title}</h2>}
            <div className="flex flex-wrap justify-center mb-12">
                {
                    ctas && ctas.map(({cta_link, cta_text, cta_colour}, i) => (
                        cta_link && cta_text && 
                            <ButtonGradient 
                                as={Link} 
                                to={`${cta_link.source_url ||cta_link}`}
                                className='w-full md:w-auto mb-4 md:mb-0 md:mr-4 text-xl border-white border-2 bg-transparent'
                                key={i}
                                colour={cta_colour}
                            >
                                {cta_text}
                            </ButtonGradient>
                    ))
                }
            </div>
            {copy && <p className="text-darkgrey text-p-fluid text-center pb-16 text-lg">{copy}</p>}
        </div>
	)
}

CTAsBlock.propTypes = {
	title: string
}

export default CTAsBlock
