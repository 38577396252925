/* eslint-disable react/prop-types */
import React, {  } from 'react'
import { string, number } from 'prop-types'
import classNames from 'classnames'
import styles from './Line.module.css';

function Line({
    colour,
    gradient
}) {
	return (
        <div className={classNames(
            styles.line,
            `bg-gradient-to-r from-${ colour }1 to-${ colour }${+gradient ? 2 : 1}`,
        )}/>
	)
}

Line.propTypes = {
	colour: string,
    gradient: string
}

export default Line
