/* eslint-disable react/prop-types */
// export { default as HeroShouter } from './HeroShouter'

import React, { useState } from 'react'
// import Image from 'gatsby-image'
import { motion } from 'framer-motion'
import classNames from 'classnames'
import Button from '@/components/global/Button'
import SectionHeading from '@/components/global/SectionHeading'
const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }

const formatList = input => {
	return input.reduce((acc, curr, index, { length }) => {
		const format = index < length - 1 ? ', ' : ''
		return `${acc}${curr}${format}`
	}, '')
}

function Deparment({ departmentMap, department }) {
	const items = department.map(item => departmentMap[item])
	return (
		<SectionHeading className="mb-4 text-xs text-white" size="w-16">
			{formatList(items)}
		</SectionHeading>
	)
}

function PeopleBlock({ people, departments }) {
	const [filter, setFilter] = useState(true)

	const departmentMap = departments.reduce(
		(acc, curr) => ({ ...acc, [curr.wordpress_id]: curr.name }),
		{}
	)

	return (
		<div className="wrapper">
			<h2 className="block w-full mb-12 leading-tight text-center text-primary text-h2-fluid font-museoSans">
				Meet the minds behind Ultraleap
			</h2>

			<ul className="flex flex-wrap items-center mb-8">
				<li className="mr-4 mb-4">
					<Button
						as="button"
						type="button"
						theme={filter === true ? 'primary' : 'white'}
						className={classNames({
							'border-primary border text-primary': filter !== true,
							'text-white': filter === true
						})}
						onClick={() => setFilter(true)}
					>
						All
					</Button>
				</li>
				{departments.map(({ name, id, wordpress_id }) => (
					<li className="mr-4 mb-4" key={id}>
						<Button
							as="button"
							type="button"
							theme={filter === wordpress_id ? 'primary' : 'white'}
							className={classNames({
								'border-primary border text-primary': filter !== wordpress_id,
								'text-white': filter === wordpress_id
							})}
							onClick={() => setFilter(wordpress_id)}
						>
							{name}
						</Button>
					</li>
				))}
			</ul>
			<div className="flex-wrap sm:flex sm:-ml-4">
				{people
					.filter(
						item =>
							typeof filter === 'boolean' || item.department.includes(filter)
					)
					.map(
						({
							title,
							id,
							featured_media,
							department,
							acf: { position, bio }
						}) => (
							<motion.div
								className="w-full mb-4 sm:w-1/2 lg:w-1/3 sm:pl-4"
								key={id}
								positionTransition={transition}
								initial={{ opacity: 1 }}
								animate={{ opacity: 1, transition }}
								exit={{ opacity: 0, transition }}
							>
								<div className="relative h-full group min-h-program-card">
									<div className="absolute top-0 w-full h-full">
										{featured_media && (
											<img
												src={featured_media.source_url}
												className="object-cover w-full h-full"
												alt={title}
												width={featured_media.media_details.width}
												height={featured_media.media_details.height}
												loading="lazy"
											/>
										)}
									</div>

									<div className="relative inset-0 z-10 flex flex-col h-full p-8 bg-black-30 transition-bg-color group-hover:bg-primary-90">
										<div className="mb-6 text-white opacity-0 pointer-events-none transition-opacity group-hover:opacity-100">
											{bio}
										</div>
										<div className="w-full mt-auto">
											<Deparment
												department={department}
												departmentMap={departmentMap}
											/>
											<h2 className="mb-1 text-xl leading-tight text-white font-museoSans">
												{title}
											</h2>
											<span className="text-xl leading-tight text-white font-museoSans">
												{position}
											</span>
										</div>
									</div>
								</div>
							</motion.div>
						)
					)}
			</div>
		</div>
	)
}

export default PeopleBlock
