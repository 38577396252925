import React, { useContext } from 'react'
import { string, shape, arrayOf, object } from 'prop-types'
import { motion } from 'framer-motion'
import Link from '@/utils/Link'
import AnimateEntrance from '@/utils/AnimateEntrance'
import SectionHeading from '@/components/global/SectionHeading'
import { containerVariants, textVariants } from '@/utils/transitions'
import { TabContext } from '@/container/TabContext'

import Button from '@/components/global/Button'
import classNames from 'classnames'

function ProductTout({ remainingItems: items }) {
	const { setTabIndex } = useContext(TabContext)
	return (
		<AnimateEntrance
			variants={containerVariants}
			threshold={0.1}
			rootMargin="-100px 0px"
			className="px-4 md:px-8 lg:mb-32 md:wrapper"
		>
			<div className="flex flex-col w-full mx-auto">
				<div 
					className={classNames(
						items.length === 2 ? "lg:grid lg:grid-columns-2" : "lg:grid lg:grid-columns-2 xl:grid-columns-3",
						"grid-gap-4"
					)}
				>
					{items.map((product, index) => {
						return (
							<motion.div
								variants={textVariants}
								className={classNames(
									index === 2 && "lg:col-span-2 xl:col-auto",
									"relative flex flex-col items-center px-8 py-20 mb-4 text-center text-white md:pt-12 lg:mb-0 md:px-10 bg-easing-t-how"
								)}
								key={index}
							>
								<SectionHeading
									size="w-10"
									className="mb-10 text-xs sm:text-sm text-white"
								>
									{product.subheading}
								</SectionHeading>
								<h2 className="relative max-w-xs mx-auto text-5xl leading-tight font-museoSansBold min-h-product-tout-text">
									{product.header}
								</h2>
								<div className="flex justify-center h-64 -ml-8 -mr-8 md:h-64 sm:m-0 sm:w-full">
									{product.image && <img
										src={product.image.source_url || product.image}
										alt="tracking one"
										className="object-contain"
									/>}
								</div>
								<p className="relative mb-auto text-base md:px-12 font-san-serif">
									{product.intro}
								</p>

								<div className="w-full mt-8 -mr-4">
									<Button
										as={Link}
										to={`${product.ctaonelink}`}
										theme="white"
										className="mb-4 mr-4 text-secondary md:mb-0"
										onClick={() => setTabIndex({ value: 2 })}
									>
										{product.ctaonecopy}
									</Button>

									{
                                        product.ctatwolink && 
                                        <Button
                                            as={Link}
                                            to={`${product.ctatwolink}`}
                                            theme="transparent"
                                            className="mr-4 text-white"
                                            onClick={() => setTabIndex({ value: 0 })}
                                        >
                                            {product.ctatwocopy}
                                        </Button>
                                    }
								</div>
							</motion.div>
						)
					})}
				</div>
			</div>
		</AnimateEntrance>
	)
}

ProductTout.propTypes = {
	remainingItems: arrayOf(
		shape({
			image: object.isRequired || string.isRequired,
			header: string.isRequired,
			intro: string.isRequired,
			ctaonelink: string.isRequired,
			ctaonecopy: string.isRequired,
			ctatwolink: string,
			ctatwocopy: string
		})
	)
}

export default ProductTout
