import React from 'react'
import styles from './Image.module.css'
import classnames from "classnames"

function CoreImage({ content }) {
	const regex = /https:\/\/ultraleap-api.mrbandfriends-staging.co.uk/gim;

	if (content.search(regex)) {
		content = content.replace(regex, `https://${process.env.SOURCE}`);
	}

	const classNames = [styles.figure];

	if (content.search(/is-resized/)) {
		classNames.push(styles.resized);
	} else {
		classNames.push(styles.image);
	}

	if (content.search(/align-center/)) {
		classNames.push(styles.centered);
	}

	return (
		<div
			className={classnames(...classNames)}
			dangerouslySetInnerHTML={{ __html: content }}
		/>
	)
}

export default CoreImage
