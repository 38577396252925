/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import classNames from 'classnames'
import Link from '@/utils/Link'
import Image from '@/utils/Image'
import RichText from '@/utils/RichText'
import Button from '@/components/global/Button'
import { TabContext } from '@/container/TabContext'
import styles from './ImageContentTrio.module.css'

function ImageContentTrio({
    media_type,
    video,
	image,
	image_width,
	content,
	heading,
	heading_colour,
	ctatext,
	ctalink,
	cta_colour,
	inline_heading,
	reverse_flow,
	onTabSelect
}) {
	const width = !image_width || image_width === 'half' ? 'lg:w-1/2' : 'lg:w-2/3'

	
	const onClick = e => {
		// if form on page, scroll to it
		const form = document.querySelector('iframe')
        if(!ctalink && form) return window.scrollTo({top: (form.offsetTop - 200), behavior: 'smooth'})
		
		// if page does not feature tabs, exit
		if(!onTabSelect) return

		// default to tab 2
		if(ctalink.includes(location.pathname)) {
			e.preventDefault()
			onTabSelect(2)
			window.scrollTo({
				top: 620,
				behavior: 'smooth'
			})
		}
	}

	return (
		<section>
			<div className="wrapper">
				{heading && (
					<div className={classNames(
						"max-w-2xl mx-auto mb-12 lg:mb-20",
						width
						)}>
						<h3
							className={classNames(
								heading_colour,
								'text-h3-fluid font-museoSans leading-tight text-center'
							)}
						>
							{heading}
						</h3>
					</div>
				)}
				<div
					className={classNames('relative lg:flex lg:-ml-24', {
						'flex-row-reverse': reverse_flow > 0
					})}
				>
					<div className={classNames(
						"mb-6 lg:mb-0 lg:pl-24",
						width
					)}>
						<div>
							{ (!media_type || media_type.toLowerCase() === 'image' ) && image && (
								<Image
									{...image}
									className={classNames(image ? image.className : '', 'w-full')}
								/>
							)}
							{media_type === 'Video' && video && (
								<video
									preload="auto"
									loop
									autoPlay
									muted
									playsInline
									src={video.source_url}
									className="object-cover w-full h-full"
								/>
							)}	
						</div>
					</div>
					<div className={classNames(
						"lg:pl-24 flex flex-col justify-center"
					)}>
						<div>
							{inline_heading ? (
								<h3
									className={classNames(
										'mb-6 text-h3-fluid leading-tight font-museoSans',
										heading_colour || "text-secondary"
									)}
								>
									{inline_heading}
								</h3>
							) : null}
							{content && (
								<RichText
									className={classNames('max-w-lg', styles.content)}
									content={content}
								/>
							)}

							<div>
								{ctatext && (
									<Button
										as={Link}
										theme={cta_colour.split("-").pop() || "secondary"}
										className="my-8 font-bold"
										to={ctalink}
										onClick={onClick}
									>
										{ctatext}
									</Button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default ImageContentTrio
