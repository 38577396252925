/* eslint-disable react/no-danger */
import React from 'react'
import fetch from 'unfetch'
import { useAsync } from 'react-async-hook'
import './HrBlock.css'

const fetchJobs = async id =>
	(
		await fetch(
			`https://ultraleap.bamboohr.com/jobs/embed2.php?departmentId=${id}`
		)
	).text()

function HrBlock() {
	const id = 0
	const asyncHero = useAsync(fetchJobs, [id])

	return (
		<div id="careers" className="wrapper">
			{asyncHero.loading && <div>Fetching Jobs</div>}
			{asyncHero.result && (
				<div
					className="max-w-4xl mx-auto"
					dangerouslySetInnerHTML={{ __html: asyncHero.result }}
				/>
			)}
		</div>
	)
}

export default HrBlock
