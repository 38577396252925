import React from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import Link from '@/utils/Link'
import AnimateEntrance from '@/utils/AnimateEntrance'
import { containerVariants, textVariants } from '@/utils/transitions'
import Button from '@/components/global/Button'

function PageHeading({ landingPage, header, copy, ctaText, ctaLink, headerWidth, id }) {
	return (
		<AnimateEntrance
			variants={containerVariants}
			className={classNames(
				landingPage ? "" : "py-24 ",
				"text-center wrapper"
			)}
			id={id}
		>
			<motion.h1
				variants={textVariants}
				className={classNames(
					'w-full py-12 pt-0 mx-auto leading-tight text-center text-h1-fluid text-secondary font-museoSansBold',
					{ 'md:w-11/12': headerWidth === 'wide' },
					{ 'md:w-6/12': headerWidth === 'narrow' }
				)}
			>
				{header}
			</motion.h1>
			{copy && (
				<motion.p
					variants={textVariants}
					className="pb-12 mx-auto text-xl text-center font-san-serif lg:w-5/12"
				>
					{copy}
				</motion.p>
			)}
			{ctaText && (
				<Button
					as={Link}
					to={ctaLink}
					theme="secondary"
					className="mx-auto text-white"
				>
					{ctaText}
				</Button>
			)}
		</AnimateEntrance>
	)
}

export default PageHeading
