import React from 'react'
import Button from '@/components/global/Button'

function CoreButton({ content }) {
	return (
		<div className="flex justify-center">
			<Button as="div">
				<div dangerouslySetInnerHTML={{ __html: content }} />
			</Button>
		</div>
	)
}

export default CoreButton
