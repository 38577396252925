import React from 'react'
import Image from '@/utils/Image'
import classNames from 'classnames'

function MultiMedia({ media_type, mm_video, mm_image, mm_caption }) {
	return (
        <div className="px-4 md:px-8 md:wrapper flex justify-center">
            <div
                name="tout-image"
                className={classNames('w-full lg:w-3/4 h-full')}
            >
                { (!media_type || media_type.toLowerCase() === 'image' ) && mm_image && (
                    <Image
                        {...mm_image}
                        className={classNames(mm_image ? mm_image.className : '', 'w-full')}
                    />
                )}
                {media_type === 'Video' && mm_video && (
                    <video
                        preload="auto"
                        loop
                        autoPlay
                        muted
                        playsInline
                        src={mm_video.source_url}
                        className="object-cover w-full h-full"
                    />
                )}
                {mm_caption && (
                    <figcaption className="text-sm text-darkgrey px-8 md:px-0" dangerouslySetInnerHTML={{ __html: mm_caption }} />
                )}
            </div>
        </div>
	)
}

export default MultiMedia
