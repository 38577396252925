import React from 'react';
import classNames from 'classnames'
import AnimateEntrance from '@/utils/AnimateEntrance'
import { containerVariants } from '@/utils/transitions'
import Link from '../../utils/Link';
import styles from './OptionsBlock.module.css';

const OptionsBlock = ({
    title,
    tag_line,
    background_gradient,
    background_colour: bg,
    background_image: image,
    size,
    text_colour,
    options
}) => {
    const getLinkStyle = ({link_colour: bg, text_colour: color, type}) => ({
        backgroundColor: type === 'transparent' ? 'transparent' : bg,
        borderColor: bg,
        color: color === 'dark' ? 'black' : color === 'light' ? 'white' : 'black',
        marginRight: '1em'
    })
	return (
        <AnimateEntrance
			variants={containerVariants}
			threshold={0}
			rootMargin="-5px 0px"
			name="container"
		>
            <section 
                className={ classNames(
                    styles.container,
                    `bg-gradient-to-b from-${ bg }1 to-${ bg }${+background_gradient ? 2 : 1}` ,
                    size === 'narrow' ? 'max-w-4xl mx-auto' : ''
                )}
                style={{
                    backgroundImage: `url('${image && image.source_url}')`
                }}
            >
                <div className={ styles.textContainer }>
                    { title && <h2 className={classNames(
                        "leading-tight text-h2-fluid font-museoSansBold mb-8", 
                        styles.title,
                        text_colour === 'dark' ? 'text-black' : 'text-white'
                    )}>{ title }</h2>}
                    { tag_line && <p className={classNames("mb-8", styles.text, text_colour === 'dark' ? 'text-black' : 'text-white')}>{ tag_line }</p>}
                    { options && options.length && options.map(({link, link_colour, text_colour, type}, i) => (
                        <Link
                            style={ getLinkStyle({link_colour, text_colour, type}) }
                            to={ link.url }
                            target={ link.target }
                            key={i}
                            className='text-lg transition-all inline-flex items-center justify-center text-sm font-museoSans py-2 px-4 hover:px-8 rounded-full'
                        >
                            { link.title }
                        </Link>
                    ))}
                </div>
            </section>
        </AnimateEntrance>
	);
};

export default OptionsBlock;