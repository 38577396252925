/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { mapSlideItems } from '@/utils'
import Image from '@/utils/Image'
import Button from '@/components/global/Button'
import AnimateEntrance from '@/utils/AnimateEntrance'
import {
	containerVariants,
	imageVariants,
	textVariants
} from '@/utils/transitions'

function SupplierBlock({
	buy_header,
	buy_image,
	background_colour,
	retailers,
	...rest
}) {
	const retailerList = mapSlideItems('retailers_', 3)(rest)

	return (
		<AnimateEntrance
			variants={containerVariants}
			className={classNames(background_colour)}
		>
			<div className="py-12 wrapper lg:py-0">
				<div className="lg:flex lg:-ml-8">
					<div className="lg:w-1/2 lg:py-32">
						<motion.h3
							variants={textVariants}
							className="mb-8 leading-tight text-center text-white text-h3-fluid font-museoSans"
						>
							{buy_header}
						</motion.h3>
						<div className="flex lg:justify-center lg:-ml-8">
							{retailerList
								.filter(({ retailer_url }) => retailer_url)
								.map(
									(
										{
											retailer_url,
											retailer_image,
											buy_now_cta_copy = 'Buy Now'
										},
										index
									) => (
										<motion.div
											className="flex flex-col items-center w-1/2 px-2 lg:pl-8"
											key={index}
											variants={textVariants}
										>
											{retailer_image && (
												<div className="w-32 mx-auto mb-8">
													<Image {...retailer_image} />
												</div>
											)}
											<Button
												className="mt-auto"
												as="a"
												theme="white"
												target="_blank"
												href={retailer_url.url}
												rel="noopener noreferrer"
											>
												{buy_now_cta_copy}
											</Button>
										</motion.div>
									)
								)}
						</div>
					</div>
					<motion.div
						variants={imageVariants}
						className="hidden lg:block lg:w-1/2"
					>
						<Image {...buy_image} style={{ width: '100%', height: '100%' }} />
					</motion.div>
				</div>
			</div>
		</AnimateEntrance>
	)
}

export default SupplierBlock
