import React, { useContext } from 'react'
import { motion } from 'framer-motion'
import { useLocation } from "@reach/router"
import classNames from 'classnames'
import useMediaQuery from '@/hooks/useMediaQuery'
import Link from '@/utils/Link'
import AnimateEntrance from '@/utils/AnimateEntrance'
import { TabContext } from '@/container/TabContext'
import {
	containerVariants,
	textVariants,
	imageVariantsAlt
} from '@/utils/transitions'
import Button from '@/components/global/Button'

// TODO... remove default prop
const W2BSplitImage = ({
	product,
	image,
	imageAlt,
	retailers,
	ctalink,
	ctatext,
	onClick,
	onTabSelect
}) => {
	const { setTabIndex } = useContext(TabContext)

	const location = useLocation()
	const scrollClick = e => {
		if(ctalink.includes('form-block')) return
		if(onTabSelect) onTabSelect(2)
		if(location.pathname.includes(ctalink)) e.preventDefault()
		window.scrollTo({
			top: 620,
			behavior: 'smooth'
		})
	}

	const isDesktop = useMediaQuery('(min-width: 48em)')

	return (
		<AnimateEntrance
			variants={containerVariants}
			className="w-full sm:mb-20 bg-primary"
		>
			<div className={classNames(
				{wrapper: isDesktop},
				"flex flex-col md:flex-row"
				)}
			>
				<div className="flex items-center w-full py-16 md:my-32 md:w-1/2 ">
					<div className="w-full text-center md:w-11/12 lg:w-9/12 md:text-left">
						<motion.h2
							variants={textVariants}
							className="px-8 sm:px-0 mb-8 leading-tight text-center text-white md:text-left font-museoSans text-h2-fluid"
						>
							Where to buy {product}
						</motion.h2>
						<div className="flex flex-row justify-center">
							{retailers &&
								retailers.map((shop, index) => {
									return (
										<div key={index} className="flex flex-col text-center">
											<div style={{ flexGrow: 1 }}>
												<motion.img
													variants={textVariants}
													src={shop.logo}
													alt={shop.name}
													className="w-10/12 mx-auto mb-6"
												/>
											</div>
											<Button
												as={Link}
												to={shop.ctalink}
												onClick={() => setTabIndex(2)}
												theme="transparent"
												className="mx-auto text-white pardotTrackClick z-50"
											>
												{shop.ctaText}
											</Button>
										</div>
									)
								})}
							{ctalink && (
								<Button
									as={Link}
									to={ctalink}
									theme="transparent"
									className="mx-auto md:ml-0 text-white"
									onClick={scrollClick}
								>
									{ctatext}
								</Button>
							)}
						</div>
					</div>
				</div>
				<div className="w-full md:w-1/2">
					<div className="w-full h-full lg:w-10/12">
                    { image && image.source_url &&
						<motion.img
							variants={imageVariantsAlt}
							className="object-cover w-full h-full"
							src={image.source_url}
							alt={imageAlt}
						/>}
					</div>
				</div>
			</div>
		</AnimateEntrance>
	)
}

export default W2BSplitImage
