import React from 'react'
import classNames from 'classnames'
import { v4 as uuidv4 } from 'uuid';
import { motion } from 'framer-motion'
import AnimateEntrance from '@/utils/AnimateEntrance'
import { containerVariants, textVariants } from '@/utils/transitions'
import styles from './Testimonials.module.css'

function Testimonials({
    dividers,
    divider_colour,
    testimonials
}) {
	return (

        <AnimateEntrance
            variants={containerVariants}
            threshold={0.1}
            rootMargin="-100px 0px"
            className="px-4 md:px-8 lg:mb-32 md:wrapper flex flex-col w-full mx-auto"
        >
            <div className={classNames(
                `md:grid-columns-${testimonials.length}`,
                "md:wrapper md:grid grid-gap-0 px-0"
            )}>
                {testimonials && testimonials.map(({image, text, author, link}, i) => (
                    <motion.div 
                        variants={containerVariants}
                        key={uuidv4()} 
                        className={classNames(
                            dividers && styles.dividers,
                            dividers && styles[`divider${divider_colour}`],
                            "mb-16 lg:mb-0 lg:px-16 px-4"
                        )}
                    >
                        {image && (
                            <div className={classNames(styles.image, 'mb-8 max-h-50 overflow-hidden')}>
                                <img src={image.source_url} alt={image.alt_text} />
                            </div>
                        )}
                        {text && (
                            <p className={styles.text}>{text}</p>   
                        )}
                        {author && (
                            <p className='text-right mt-4'>{author}</p>   
                        )}
                    </motion.div>
                ))}
            </div>
        </AnimateEntrance>
	)
}

export default Testimonials
