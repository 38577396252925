import React, { useState, useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { v4 as uuidv4 } from 'uuid';
import { string, shape, arrayOf, number } from 'prop-types'
import { motion, AnimatePresence } from 'framer-motion'
import classNames from 'classnames'
import { cleanProps } from '@/utils'
import useConstant from '@/hooks/useConstant'
import Link from '@/utils/Link'

import Button from '@/components/global/Button'
import Select from '@/components/Select/Select'
import styles from './Where2Buy.module.css'


function Where2Buy({ 
	countries, 
	heading, 
	media 
}) {
	const options = countries.map(country => ({value: country.name}))
	
	const retailersQuery = useStaticQuery(graphql`
	query wtbQuery{
		retailers: allWordpressWpRetailer {
			nodes {
				...retailerQuery
			}
		}
	}
	`)
	
	const retailers = retailersQuery.retailers.nodes.map(retailer => {
		const blocks = useConstant(() => cleanProps(retailer.blocks, media))
		return {
			wordpress_id: retailer.wordpress_id,
			...blocks[0]
		}
	})

	
	countries = countries.map(country => ({
		...country,
		retailers: country.retailers.map(countryRetailer => retailers.find(retailer => retailer.wordpress_id === countryRetailer.retailer))
                                    .filter(retailer => retailer)
	}))

	const [selectedCountry, setSelectedCountry] = useState(countries[0])
	
	const handleChange = event => {
		setSelectedCountry(countries.find(country => country.name === event.target.value))
	}

	return (
		<div className="w-full py-24 mb-32 bg-primary">
			<div className="wrapper">
				<div className="flex flex-col w-full pb-4 text-left text-white sm:pb-16 sm:flex-row">
					{heading && (
						<h2 className="pb-4 leading-tight sm:mr-8 text-h2-fluid sm:pb-0 font-museoSans">
							{heading}
						</h2>
					)}
					{options && options.length > 1 && (
						<Select
							value={selectedCountry.name}
							onChange={handleChange}
							options={options}
						/>
					)}
				</div>
				<div
					className={classNames(styles.retailer, 'flex flex-wrap justify-center sm:justify-left items-center')}
				>
					<AnimatePresence>
						<>
							{selectedCountry.retailers.map((retailer, i, array) => (
								<motion.div
									positionTransition
									exit={{ opacity: 0 }}
									key={uuidv4()}
									className={classNames(
										{ "lg:w-1/3": array.length > 4 },
										{ "lg:w-1/4": array.length < 5 },
										"relative flex flex-col items-center justify-between w-full mt-16 text-center sm:w-1/2 md:w-1/3"
									)}
								>
									<div className={styles.wtblogocont}>
											<img
												src={retailer.logo && retailer.logo.source_url}
												alt={retailer.name}
												className="w-10/12 mx-auto"
												style={{ maxWidth: '215px' }}
										/>
									</div>
									<Button
										as={Link}
										to={retailer.link}
										theme="transparent"
										className="mt-4 mb-6 text-white hover:text-secondary hover:bg-white sm:mb-0 sm:mt-12"
									>
										Buy now
									</Button>
								</motion.div>
							))}
						</>
					</AnimatePresence>
				</div>
			</div>
		</div>
	)
}

Where2Buy.propTypes = {
	heading: string,
	countries: arrayOf(
		shape({
			name: string.isRequired,
			retailers: arrayOf(
				shape({
					retailer: number.isRequired
				})
			).isRequired
		})
	),
	media: string.isRequired
}

export default Where2Buy
