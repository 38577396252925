import React from 'react'
import classNames from 'classnames'
import RichText from '@/utils/RichText'
import styles from './Group.module.css'

function Group({ content }) {
	return (
		<div key="content-block" className="wrapper">
			<RichText
				content={content}
				className={classNames(styles.post, 'max-w-3xl mx-auto text-left')}
			/>
		</div>
	)
}

export default Group
