import React from 'react'
import { string, arrayOf, Int, object, bool, oneOfType } from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { useNavigate } from "@reach/router"
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames'
import IconWrapper from '@/utils/IconWrapper'
import TickIcon from '@/icons/tick.svg'
import CrossIcon from '@/icons/delete.svg'
import Link from '@/utils/Link'
import Button from '@/components/global/Button'

function Cell({ value, textAndIcon = false }) {
	const Icon = value === true ? TickIcon : value === false ? CrossIcon : null

	if (textAndIcon) {
		return (
			<div className="flex flex-col items-center justify-center">
				<IconWrapper wrapperClassName="w-5 mx-auto mb-4" icon={TickIcon} />
				<span>{value}</span>
			</div>
		)
	}

	if (Icon === null) return value

	return <IconWrapper wrapperClassName="w-5 mx-auto" icon={Icon} />
}

Cell.propTypes = {
	value: oneOfType([string, bool]).isRequired,
	textAndIcon: bool
}

function Table({ rows, data, textAndIcon = false, title }) {
    const numberOfCols = data.length
    const [a, b, c] = data
	return (
		<>

			<div className="lg:mx-auto border-t">
				{rows.map((key, i) => {
                    const valueA = a && Object.values(a)[i]
                    const valueB = b && Object.values(b)[i]
                    const valueC = c && Object.values(c)[i]
                    const values = [valueA, valueB, valueC]

                    return (
                        (valueA + valueB + valueC !== "") && (valueA || valueB || valueC) && (
                            <div
                                className="flex flex-wrap w-full py-3 -ml-4 md:border-b md:border-lightgrey md:py-4  md:ml-0 md:items-center lg:py-6"
                                key={key}
                            >
                                <div className={classNames({
                                        'w-1/3 md:w-1/4': numberOfCols > 2,
                                        'w-1/2 md:w-1/3': numberOfCols < 3,
                                    }, "w-full pl-4 mb-2 text-sm text-gray-500 uppercase md:w-1/3 md:mb-0")}
                                >
                                    {key}
                                </div>
                                {data.map((cell, index) => {
                                    if(!cell) return
                                    return (
                                        <div
                                            key={uuidv4()} 
                                            className={classNames({
                                                'w-1/3 md:w-1/4': numberOfCols > 2,
                                                'w-1/2 md:w-1/3': numberOfCols < 3,
                                            }, "pl-4 text-center text-secondary md:px-4")}
                                        >
                                            <Cell textAndIcon={textAndIcon} value={values[index]} />
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    )
                })}
			</div>
		</>
	)
}

Table.propTypes = {
	textAndIcon: bool,
	rows: arrayOf(string).isRequired,
	data: arrayOf(object).isRequired,
	title: string.isRequired
}

function ProductCompare({ 
    className, 
    title, 
    products_to_compare_product_1, 
    products_to_compare_product_2, 
    products_to_compare_product_3,
    products_to_compare_cta_1, 
    products_to_compare_cta_2, 
    products_to_compare_cta_3,
    onTabSelect
}) {

	const productsQuery = useStaticQuery(graphql`
		query ProductCompareQuery {
			products: allWordpressWpProducts {
				nodes {
					...productQuery
				}
			}
		}
	`)
	
    const products = [products_to_compare_product_1, products_to_compare_product_2, products_to_compare_product_3]
        .filter((id) => id)
        .map(id => {
            return productsQuery.products.nodes.find(post => post.wordpress_id === id)
        })
    
    const ctas = [products_to_compare_cta_1, products_to_compare_cta_2, products_to_compare_cta_3]
        .filter((cta) => cta)

    const navigate = useNavigate()

	const ctaOnClick = (link) => {
        if(link === '#pricingandlicensing') {
            if(onTabSelect) onTabSelect(2)
            window.scrollTo({ top: 680,behavior: 'smooth' })
        } else if(link === '#contact') {
            const form = document.querySelector('iframe')
            if(form) window.scrollTo({top: (form.offsetTop - 200), behavior: 'smooth'})
        } else {
            navigate(link)
        }
	}

	const hardware = products.map(product => product.acf.hardware)
	const applications = products.map(product => product.acf.applications)
	
	const hardWareKeys = [
		"Usage",
		"Product dimensions", 
		"Array size", 
		"Leap Motion Controller for hand tracking", 
		"Update rate", 
		"Maximum range", 
		"Frame-mounted cover materials",
        "Certified for public use",
        "Tracking range",
        "Field of view"
	]

	const applicationKeys = [
		"Automotive",
		"Digital out-of-home",
		"Kiosks",
		"AR / VR",
		"Appliances and smart home",
		"Industrial and medical",
		"Gaming",
		"Computing"
    ]

	return (
		<div className={classNames(className, 'wrapper py-24')}>
			<div className="flex flex-wrap mb-8 -ml-4">
				<div 
                    className={classNames({
                        'md:w-1/4': products.length > 2,
                        'md:w-1/3': products.length < 3,
                    }, 'w-full pl-4 md:flex md:items-center')}
                >
					<h2 className="mb-8 leading-tight text-h2-fluid font-museoSansBold text-secondary">
						{title}
					</h2>
				</div>
				{products.map(product => (
					<div
						key={product.title}
                        className={classNames({
                            'md:w-1/4 w-1/3': products.length > 2,
                            'md:w-1/3 w-1/2': products.length < 3,
                        }, 'flex flex-col pl-4')}
					>
						<img src={product.featured_media ? product.featured_media.source_url : ""} alt="product" className="mx-auto my-auto" />
						<h4 className="text-center font-museoSansBold">{product.acf.long_title}</h4>
					</div>
				))}
			</div>

			<div>
				<Table rows={hardWareKeys} data={hardware} title="Hardware" />
			</div>

            {ctas.length > 0 && (
                <div className="flex flex-wrap w-full py-3 -ml-4 md:border-b md:border-lightgrey md:py-4  md:ml-0 md:items-center lg:py-6">
                    <div className={classNames({
                            'w-1/3 md:w-1/4': ctas.length > 2,
                            'w-1/2 md:w-1/3': ctas.length < 3,
                        }, "w-full pl-4 mb-2 text-sm text-gray-500 uppercase md:w-1/3 md:mb-0")}
                    />
                    {ctas.map((cta) => (
                        <div 
                            key={uuidv4()} 
                            className={classNames({
                                'w-1/3 md:w-1/4': ctas.length > 2,
                                'w-1/2 md:w-1/3': ctas.length < 3,
                            }, "pl-4 text-center text-secondary md:px-4")}
                        >
                            <Button
                                as="span"
                                theme="secondary"
                                onClick={() => ctaOnClick(cta.url)}
                            >
                                {cta.title}
                            </Button>
                        </div>
                    ))}
                </div>
            )}

			{/* <div>
				<Table
					rows={applicationKeys}
					data={applications}
					title="Applications"
				/>
			</div> */}
		</div>
	)
}

ProductCompare.propTypes = {
	className: string,
	title: string,
	// products_to_compare_product_1: Int,
	// products_to_compare_product_2: Int
	
}

export default ProductCompare
