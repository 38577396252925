/* eslint-disable react/prop-types */
/* eslint-disable react/no-danger */
import React, { useState, useEffect, useRef } from 'react'
import Slider from 'react-slick'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import useMediaQuery from '@/hooks/useMediaQuery'
import Image from '@/utils/Image'
import Button from '@/components/global/Button'
import SectionHeading from '@/components/global/SectionHeading'
import styles from './CardSlider.module.css'
import AnimateEntrance from '@/utils/AnimateEntrance'
import Link from '@/utils/Link'

import { textVariants, containerVariants } from '@/utils/transitions'

function CardSlider({
	slides,
	Heading: heading = 'Making headlines. <br />See all our latest news, stories, and case studies.'
}) {
	const isDesktop = useMediaQuery('(min-width: 48em)')

	const images = slides.map(slide => slide.image)
	const landscapeImages = slides.map(slide => slide.landscape_image)
	const content = slides.map(({ image, ...rest }) => ({ ...rest }))

	const [imageSlide, setImageSlide] = useState()
	const [contentSlide, setContentSlide] = useState()

	const imageSlider = useRef()
	const contentSlider = useRef()

	const imageSliderSettings = {
		className: styles.imageSlider,
		infinite: true,
		variableWidth: true,
		centerMode: true,
		speed: 750,
		arrows: false,
		dots: false,
		responsive: [
			{
				breakpoint: 1310,
				settings: {
					centerMode: false
				}
			}
		]
	}

	const contentSliderSettings = {
		infinite: true,
		speed: 750,
		arrows: false,
		className: styles.copySlider,
		nav: false,
		dots: true,
		adaptiveHeight: false,
		cssEase: 'ease-in-out',
		responsive: [
			{
				breakpoint: 1310,
				settings: {
					centerMode: false
				}
			}
		]
	}

	useEffect(() => {
		if (!imageSlide && !contentSlide) {
			setImageSlide(imageSlider.current)
			setContentSlide(contentSlider.current)
		}
	}, [contentSlide, contentSlider, imageSlide, imageSlider])

	return (
		<AnimateEntrance
			rootMargin="-10% 0px"
			variants={containerVariants}
			onEnter={() => {
				imageSlider.current.slickNext()
			}}
			className="relative py-20 overflow-hidden bg-lightgrey md:py-24 lg:py-24 lg:overflow-visible"
		>
			{heading && (
				<motion.h3
					variants={textVariants}
					dangerouslySetInnerHTML={{ __html: heading }}
					className="max-w-3xl px-8 pb-12 mx-auto leading-tight text-center text-darkgrey md:pb-20 text-h3-fluid font-museoSans"
				/>
			)}
			<div className="relative">
				<Slider
					fade={false}
					swipeToSlide
					focusOnSelect
					ref={imageSlider}
					asNavFor={contentSlide}
					slidesToShow={1}
					{...imageSliderSettings}
				>
					{images.map((image, index) => (
						<div
							className={classNames(
								'md:pr-3 w-full slide-item px-3 md:px-0',
								styles.slideItem
							)}
							key={index}
						>
							{ landscapeImages[index] && isDesktop && <Image style={{ height: '100%', width: '100%' }} {...landscapeImages[index]} />}
							{ (!landscapeImages[index] || !isDesktop) && <Image style={{ height: '100%', width: '100%' }} {...image} /> }
						</div>
					))}
				</Slider>
				<AnimateEntrance
					variants={containerVariants}
					className="relative wrapper"
				>
					<div className="px-2 lg:w-10/12 lg:mx-auto">
						<div
							className={classNames(
								'max-w-xl lg:max-w-full lg:w-7/12 xl:w-5/12 z-10 md:pl-4 -mt-8 md:-mt-48 mx-auto lg:mr-8',
								styles.copySlider
							)}
						>
							<Slider
								fade
								slidesToShow={1}
								swipeToSlide
								ref={contentSlider}
								asNavFor={imageSlide}
								{...contentSliderSettings}
							>
								{content.map(
									(
										{ title, heading: subHeading, copy, ctatext, ctalink, id },
										k
									) => (
										<div
											className="flex flex-col w-full p-4 text-left bg-white shadow cursor-default md:p-8 lg:p-10"
											key={id || k}
											style={{ display: 'flex' }}
										>
											<SectionHeading
												size="w-16"
												className="hidden mb-4 text-xs text-darkgrey md:flex"
											>
												{title}
											</SectionHeading>
											<motion.h3
												variants={textVariants}
												className="mb-6 text-left text-primary font-museoSans text-h4-fluid"
												dangerouslySetInnerHTML={{ __html: subHeading }}
											/>
											<motion.p
												variants={textVariants}
												className="hidden mb-6 md:block"
												dangerouslySetInnerHTML={{ __html: copy }}
											/>
											<motion.div className="mt-auto" variants={textVariants}>
												<Button as={Link} to={ctalink}>
													{ctatext}
												</Button>
											</motion.div>
										</div>
									)
								)}
							</Slider>
						</div>
					</div>
				</AnimateEntrance>
			</div>
		</AnimateEntrance>
	)
}

export default CardSlider
