/* eslint-disable react/prop-types */
import React from 'react'
import { string } from 'prop-types'
import { motion } from 'framer-motion'
import Link from '@/utils/Link'
import Button from '@/components/global/Button'
import Image from '@/utils/Image'
import RichText from '@/utils/RichText'
import SectionHeading from '@/components/global/SectionHeading'
import AnimateEntrance from '@/utils/AnimateEntrance'
import {
	imageVariants,
	textVariants,
	containerVariants
} from '@/utils/transitions'

function BannerTout({ bg, image, header, title, copy, btnText, btnLink }) {
	return (
		<div style={{ backgroundColor: bg }} className="w-full py-12 md:py-0">
			<div className="lg:wrapper">
				<AnimateEntrance
					rootMargin="-10% 0px"
					variants={containerVariants}
					className="sm:items-center md:flex md:items-stretch md:-ml-16 lg:-ml-24"
				>
					{image && (
						<motion.div
							variants={imageVariants}
							className="w-full mb-12 md:w-1/2 md:pl-16 lg:pl-24 md:mb-0"
						>
							<Image style={{ height: '100%' }} {...image} />
						</motion.div>
					)}

					<div className="w-full max-w-2xl px-4 md:w-1/2 md:px-16 md:pl-16 lg:pl-24 md:py-32">
						{header && (
							<SectionHeading className="mb-8 text-xs text-white" size="w-20">
								{header}
							</SectionHeading>
						)}
						<motion.h3
							variants={textVariants}
							className="mb-4 leading-tight text-white text-h3-fluid font-museoSans"
						>
							{title}
						</motion.h3>
						<motion.div variants={textVariants}>
							<RichText content={copy} className="mb-8 text-white" />
						</motion.div>
						{btnLink && (
							<motion.div variants={textVariants}>
								<Button as={Link} to={btnLink} theme="white">
									{btnText}
								</Button>
							</motion.div>
						)}
					</div>
				</AnimateEntrance>
			</div>
		</div>
	)
}

BannerTout.propTypes = {
	bg: string,
	// image: imageType,
	header: string,
	title: string,
	copy: string,
	btnText: string,
	btnLink: string
}

export default BannerTout
