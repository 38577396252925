import React from 'react'
import classNames from 'classnames'
import { v4 as uuidv4 } from 'uuid';
import { motion } from 'framer-motion'
import ButtonGradient from '@/components/global/ButtonGradient'
import Link from '@/utils/Link'
import Node from '@/utils/Node'
import AnimateEntrance from '@/utils/AnimateEntrance'
import { containerVariants, textVariants } from '@/utils/transitions'
import styles from './ColumnsBlock.module.css'

function ColumnsBlock({
    title,
    columns,
    cta_colour = 'green',
    gradient = true,
    copy
}) {
    const hasGradient = gradient === '1' || gradient === true
	return (
        <AnimateEntrance
            variants={containerVariants}
            threshold={0.1}
            rootMargin="-100px 0px"
            className="px-4 md:px-8 lg:mb-32 md:wrapper"
        >
            <div className="flex flex-col w-full mx-auto">
                {title && <h2 className='text-darkgrey text-h2-fluid font-museoSans leading-tight text-center pb-8'>{title}</h2>}
                {copy && <p className='text-darkgrey text-p-fluid text-center pb-8'>{copy}</p>}
                <div className={classNames(
                    columns.length % 2 === 0 ? 'md:grid-columns-2' : 'md:grid-columns-3',
                    "md:grid grid-gap-4"
                )}>
                    {columns.map(({image, copy: text, cta_link, cta_text, image_style: style}) => {
                        const center = text && !!text.match(/text-align: center/)
                        return (
                            <motion.div 
                                variants={textVariants}
                                key={uuidv4()} 
                                className={classNames("mb-16 lg:mb-4", {'text-center': center})}
                            >
                                {image && (
                                    <div
                                        className={
                                            classNames(
                                                styles.image, 
                                                style === 'rounded' && styles.imageRounded,
                                                'mb-8 max-h-50 overflow-hidden')
                                        }
                                    >
                                        <img src={image.source_url} alt={image.alt_text}/>
                                    </div>
                                )}
                                {text && (
                                    <Node
                                        as="div"
                                        className={classNames(styles.text, 'mb-8')}
                                        dangerouslySetInnerHTML={{ __html: text }}
                                    />
                                )}
                                {cta_link && cta_text && (
                                    <ButtonGradient 
                                        as={Link} 
                                        to={`${cta_link}`} 
                                        hasGradient={hasGradient}
                                        colour={cta_colour}
                                    >
                                        {cta_text}
                                    </ButtonGradient>
                                )}
                            </motion.div>
                        )
                    })}
                </div>
            </div>
        </AnimateEntrance>
	)
}

export default ColumnsBlock
