/* eslint-disable react/prop-types */
import React, { useState, useCallback, useContext, useRef, useEffect } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { graphql, useStaticQuery } from 'gatsby'
import classNames from 'classnames'
import Blocks from '@/utils/Blocks'
import { TabContext } from '@/container/TabContext'
import { cleanProps } from '@/utils'
import useConstant from '@/hooks/useConstant'

function TabsBlock({ 
	tabs,
	media,
	currentPage,
	pageTitle
}) {
	const [localTabIndex, setLocalTabIndex] = useState(0)
	const { tabIndex, setTabIndex } = useContext(TabContext)
	
	const [titleIsVisible, setTitleIsVisible] = useState(false)
	const [tabsHeight, setTabsHeight] = useState(false)
	
	const pagesQuery = useStaticQuery(graphql`
	query tabsPagesQuery{
		pages: allWordpressPage(sort: { fields: date, order: DESC }) {
			nodes {
				...pageQuery
			}
		}
	}
	`)
	
	// get the blocks the page stipulated by the tab
	let formattedTabs = tabs.map(tab => {
		const { blocks } = pagesQuery.pages.nodes.find(page => page.wordpress_id === tab.tab_page)
		tab.blocks = useConstant(() => cleanProps(blocks, media))
		tab.blocks = tab.blocks.map(block => ({...block, isTabBlock: true}))
		tab.id = tab.tab_label.replace(/[^A-Za-z]+/g, '').toLowerCase()
		return tab
	})
	
	// protect against user choosing current page and creating infinite loop
	formattedTabs = tabs.filter(tab => tab.tab_page !== currentPage)

	const onSelect = useCallback(
		index => {
			setLocalTabIndex(index)
			setTabIndex({ value: null })
		},
		[setTabIndex]
	)

	const tabsRef = useRef(null)

	const handleTabScroll = (e) => {
		if(window.pageYOffset && window.pageYOffset > tabsHeight) window.scrollTo({top: 0})
		window.scrollTo({top: tabsRef.current.offsetTop, behavior: 'smooth'})
		window.location.hash = e.currentTarget.getAttribute('data-hash')
	}

	useEffect(() => {
		window.scrollTo({top: 0})
		window.onscroll = () => !titleIsVisible && setTitleIsVisible(true)
		setTabsHeight( document.getElementById('_tabs_').offsetTop)
		const tabI = formattedTabs.findIndex(tab => `#${tab.id}` === window.location.hash);
		if(tabI > -1) {
			setLocalTabIndex(tabI)
			setTabIndex({ value: null })
			window.scrollTo({top: tabsRef.current.offsetTop, behavior: 'smooth'})
		} 
	}, [])

	return (
		<>	
			<Tabs
				selectedIndex={
					tabIndex && tabIndex.value ? tabIndex.value : localTabIndex
				}
				onSelect={onSelect}
				selectedTabClassName="child:x-0 child:opacity-100 text-secondary"
				className="bg-white"

			>
				<div id="_tabs_" className="pt-3 relative sticky top-0 z-40 bg-white border-b border-lightgrey" ref={tabsRef}>
					<div className="wrapper">
						{pageTitle && (
							<p className={`py-4 text-sm font-museoSans text-secondary transition-opacity opacity-${titleIsVisible ? 100 : 0}`}>
								{pageTitle}
							</p>
						)}
						<TabList className="flex">
							{formattedTabs.map((tab, i) => (
								<Tab
									className="relative block py-3 mr-5 overflow-hidden cursor-pointer font-museoSansBold group min-w-1/4 w-auto sm:min-w-0 lg:mr-8"
									key={i}
									onClick={handleTabScroll}
									data-hash={tab.id}
								>
									{tab.tab_label}
									<span className="absolute bottom-0 left-0 w-full h-2 opacity-0 md:block bg-secondary -x-100 group-hover:x-0 group-hover:opacity-100 transition-both " />
								</Tab>
							))}
						</TabList>
					</div>
				</div>

                {formattedTabs.map((tab, i) => {
					return (
						<TabPanel
							key={i}
							className={classNames({
								'pt-16': i === localTabIndex && tab.blocks.length && !['acf/hero', 'acf/where-to-buy', 'acf/licensing-table'].includes(tab.blocks[0].type) 
							})}
						>
							{ tab.blocks ? <Blocks data={tab.blocks} media={media} onTabSelect={onSelect}/> : null }
						</TabPanel>
					)
				}
				)}
			</Tabs>
		</>
	)
}

export default TabsBlock
