/* eslint-disable react/prop-types */
import React from 'react'
import { CaseStudyCard } from '@/components/CaseStudyCard'
function CaseStudyCollection({ entries }) {
	return (
		<div className="wrapper">
			<div className="md:flex md:-ml-4 lg:-ml-8 md:flex-wrap md:justify-center">
				{entries.map(({ id, title, image, path }) => (
					<CaseStudyCard
						className="mb-4 md:mb-8"
						key={id}
						title={title}
						path={path}
						image={image}
					/>
				))}
			</div>
		</div>
	)
}

export default CaseStudyCollection
