/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React from 'react'
import Slider from 'react-slick'
import classNames from 'classnames'
import 'slick-carousel/slick/slick.css'
import styles from './QuoteCollection.module.css'

function QuoteCollection({ quote_intro, related }) {
	const settings = {
		infinite: false,
		centerMode: false,
		variableWidth: true,
		speed: 500,
		arrows: false,
		dots: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 960,
				settings: {
					variableWidth: false,
					centerMode: false
				}
			}
		]
	}

	if (!related) return null

	return (
		<div className="relative w-full overflow-hidden">
			<div className="flex justify-end mx-auto lg:justify-end md:overflow-visable wrapper">
				<div className="w-full md:w-11/12">
					<h3 className="mb-8 leading-tight text-h3-fluid font-museoSans text-primary">
						{quote_intro}
					</h3>
					<Slider
						className={classNames(styles.slide, 'w-full', 'quote-collection__slider')}
						slidesToShow={1}
						swipeToSlide
						{...settings}
					>
						{related.map(({ wordpress_id, content }) => (
							<div
								key={wordpress_id}
								className={classNames(
									'bg-lightgrey p-6 md:p-12 lg:p-20 md:p-10 mr-3 mr-3 max-w-5xl',
									styles.slideItem
								)}
							>
								<div
									data-content
									className="text-white opacity-0 transition-opacity font-museoSans text-h4-fluid"
									dangerouslySetInnerHTML={{ __html: content }}
								/>
							</div>
						))}
					</Slider>
				</div>
			</div>
		</div>
	)
}

export default QuoteCollection
