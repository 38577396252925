/* eslint-disable react/prop-types */
import React from 'react'
import classNames from 'classnames'
import styles from './TimelineBlock.module.css'
import Image from '@/utils/Image'

function TimelineBlock({ title, timeline_image }) {
	return (
		<div className="wrapper">
			<div className="lg:px-4">
				<h2 className="mb-8 text-h4-fluid text-primary font-museoSans">{title}</h2>
				<div className={classNames(styles.timeline, 'overflow-x-scroll')}>
					<div className="lg:w-full lg:h-full">
						<Image style={{ minWidth: 820 }} {...timeline_image} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default TimelineBlock
