/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import styles from './HeroShouter.module.css'
import AnimateEntrance from '@/utils/AnimateEntrance'
import Image from '@/utils/Image'
import {
	bannerVariants,
	textVariants,
	containerVariants
} from '@/utils/transitions'

function HeroShouter({
	key_word,
	key_copy,
	key_image,
	className = 'py-20 lg:py-24 text-secondary'
}) {
	return (
		<AnimateEntrance
			threshold={0}
			rootMargin="-30% 0px"
			variants={containerVariants}
			className={classNames(
				'relative flex items-center w-full',
                styles.container,
				className
			)}
		>
			<motion.div
				variants={bannerVariants}
				className="absolute inset-y-0 flex items-center mx-auto md:inset-0 -left-1/2 -right-1/2 md:w-full"
			>
				{key_image ? (
					<Image
						className="object-contain"
						style={{ opacity: 0.5, height: '150%', width: '100%' }}
						{...key_image}
					/>
				) : (
					<div
						className={classNames(
							'relative text-center font-bold mx-auto opacity-5',
							styles.heading
						)}
						dangerouslySetInnerHTML={{ __html: key_word }}
					/>
				)}
			</motion.div>
			<motion.div
				variants={textVariants}
				className="relative left-0 right-0 max-w-4xl p-8 mx-auto my-0 text-2xl text-center lg:text-3xl top-2 font-museoSans"
				dangerouslySetInnerHTML={{
					__html: key_copy.replace(/(?:\r\n|\r|\n)/g, '<br>')
				}}
			/>
		</AnimateEntrance>
	)
}

export default HeroShouter
