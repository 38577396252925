import React from 'react'
// import { string, shape, arrayOf, object } from 'prop-types'
import { v4 as uuidv4 } from 'uuid';
import Link from '@/utils/Link'
import classNames from 'classnames'

function Logos({
	id, logos, title, copy
}) {
	return (
		<div className="px-8 bg-white wrapper">
			<div className="w-full text-center">
				{title && <h2 className="text-darkgrey text-h2-fluid font-museoSans leading-tight text-center pb-8">{title}</h2>}
				{copy && <p className="text-darkgrey text-p-fluid text-center pb-16">{copy}</p>}

				<div className={classNames(
					"grid grid-columns-2 grid-gap-8 justify-items-center",
					logos.length === 2 && "lg:grid-cols-2"
					|| logos.length === 3 && "lg:grid-cols-3"
					|| logos.length === 4 && "lg:grid-cols-4"
					|| logos.length === 5 && "lg:grid-cols-5"
					|| logos.length % 4 === 0 && "lg:grid-cols-4"
					|| logos.length % 5 === 0 && "lg:grid-cols-5"
					|| logos.length % 6 === 0 && "lg:grid-cols-3"
					|| logos.length % 7 === 0 && "lg:grid-cols-4"
					|| logos.length % 9 === 0 && "lg:grid-cols-4"
				)}
				>
					{logos.map(({logo, link}, i) => logo && logo.source_url ? (
						<Link className="flex items-center justify-center" key={uuidv4()} to={link || ''}>
							<img
								style={{ maxWidth: 200, maxHeight: 200, margin: 'auto' }}
								src={logo.source_url}
								alt={logo.alt_text}
							/>
						</Link>
					) : null)}
				</div>
			</div>
		</div>
	)
}

// Logos.propTypes = {
// 	logos: arrayOf(
// 		shape({
// 			logo: object,
// 			link: string
// 		})
// 	).isRequired
// }

export default Logos
