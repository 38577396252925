import React from 'react'
import classNames from 'classnames'
import { v4 as uuidv4 } from 'uuid';
import { motion } from 'framer-motion'
import AnimateEntrance from '@/utils/AnimateEntrance'
import { containerVariants, textVariants } from '@/utils/transitions'
import styles from './PanelsBlock.module.css'

function PanelsBlock({
    title,
    panels,
    panels_colour,
    panels_style
}) {

	return (
        <AnimateEntrance
            variants={containerVariants}
            threshold={0.1}
            rootMargin="-100px 0px"
            className="px-4 md:px-8 lg:mb-32 md:wrapper flex flex-col w-full mx-auto"
        >
            {title && <h2 className='text-darkgrey text-h2-fluid font-museoSans leading-tight text-center pb-8'>{title}</h2>}
            <div
                className={classNames(
                    `md:grid-cols-${panels.length}`,
                    'md:wrapper grid grid-cols-1 vsm:grid-cols-2 grid-gap-4 px-0',
                )}
            >
                {panels && panels.map(({icon, title: panelTitle, copy}, i) => (
                    <motion.div
                        variants={textVariants}
                        key={uuidv4()}
                        className={classNames(
                            "mb-16 lg:mb-0 lg:px-16 px-4 py-8",
                            styles.card
                        )}
                        style={{
                            backgroundColor: panels_colour,
                            borderRadius: panels_style === 'rounded' ? '20px' : '0'
                        }}
                    >
                        {icon && (
                            <div className={classNames('mb-16 h-40 w-full')}>
                                <img className={styles.image} src={icon.source_url} alt={icon.alt_text} />
                            </div>
                        )}
                        <div className="flex flex-col justify-between mb-8 h-full">
                            {panelTitle && (
                                <h3 className="text-white font-museoSans text-2xl mb-4 text-bold">{panelTitle}</h3>
                            )}
                            {copy && (
                                <p className="text-white">{copy}</p>
                            )}
                        </div>
                    </motion.div>
                ))}
            </div>
        </AnimateEntrance>
	)
}

export default PanelsBlock
